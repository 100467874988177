import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  DetailedFormContainer,
  SideContainer,
  LargeText,
  StyledSpan,
  MediumText,
} from "../styles";
import { ProjectsList } from "./ProjectsList";
import { filterProjects } from "../../../api/prequalify";
import { ProjectRecentCard } from "./RecentCard";
import { SearchBar } from "./SearchBar";
import { FunctionalButton } from "../../../components/functionalButton/index";
import {
  DetailContent,
  DetailArea,
  HeaderArea,
  TouchScrollDiv,
} from "./styles";
import { AddProjectPopup } from "../../../components/addProjectPopup";
import { saveProject, checkProjectsLimit } from "../../../api/dashboard";
import { getSingleProject, deleteSingleProject } from "../../../api/prequalify";
import AddNewButtonIcon from "../../../assets/dashboardIcons/addNewButtonIcon";
import { IconButton } from "@material-ui/core";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../data/selectors/contact";
import { useLocation } from "react-router";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

function SeeAllProjects() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const location = useLocation();
  const institution_id = location?.state?.institution_id || "";
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  return (
    <ScreenRoot isMobile={isMobile}>
      <Project
        institution_id={institution_id}
        currentDashboardUrl={currentDashboardUrl}
      />
    </ScreenRoot>
  );
}

export default SeeAllProjects;

export const Project = ({
  noWidthChange,
  institution_id,
  currentDashboardUrl,
}) => {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [project, setProject] = useState("");
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const projectsLimit = contactData?.user_access_control?.number_of_projects;
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);

  const handleFilter = async (filterQuery) => {
    let data = {};

    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      data = { institution_id: institution_id };
    }
    await filterProjects(filterQuery, data)
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleFilter(query);
  }, []);

  // add button

  const handleAdd = (data) => {
    if (filteredData && filteredData.length >= projectsLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }

    // checkProjectsLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };
  const addProject = (data) => {
    let newProps = { ...data };
    if (
      institution_id &&
      currentDashboardUrl === "/service-provider-dashboard"
    ) {
      newProps = { ...newProps, institution_id: institution_id };
    }
    saveProject(newProps, onSuccess, () => {});
  };
  const onSuccess = () => {
    setProject("");
    setQuery("");
    handleFilter("");
  };

  useEffect(() => {
    if (!openPopup) {
      setProject("");
    }
  }, [openPopup]);

  //three dots button
  const editProject = (id) => {
    getSingleProject(id, onSuccessEdit, () => {});
  };
  const onSuccessEdit = (res) => {
    setProject(res);
    setOpenPopup(true);
  };

  const deleteProject = (id) => {
    deleteSingleProject(id, onSuccessDelete, () => {});
  };
  const onSuccessDelete = (res) => {
    setQuery("");
    handleFilter("");
  };

  return (
    <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
      <HeaderArea>
        <LargeText isMobile={isMobile}>All Company Projects</LargeText>
        {isMobile && (
          <IconButton onClick={handleAdd}>
            <AddNewButtonIcon />
          </IconButton>
        )}
        {!isMobile && (
          <HeaderArea width="50%">
            <FunctionalButton
              buttonTitle="Add Project"
              handleButton={handleAdd}
              width="40%"
            />
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              internalSearch={true}
            />
          </HeaderArea>
        )}
      </HeaderArea>
      {/* {isMobile && (
        <TouchScrollDiv>
          {filteredData?.length > 0 &&
            filteredData.slice(0, 4).map((item, i) => {
              return (
                <ProjectRecentCard
                  item={item}
                  key={i}
                  editFunc={editProject}
                  deleteFunc={deleteProject}
                  isMobile={isMobile}
                />
              );
            })}
        </TouchScrollDiv>
      )}
      {!isMobile && (
        <DetailArea>
          <MediumText>Recently Added</MediumText>
          <DetailContent>
            {filteredData?.length > 0 &&
              filteredData.slice(0, 4).map((item, i) => {
                return (
                  <ProjectRecentCard
                    item={item}
                    key={i}
                    editFunc={editProject}
                    deleteFunc={deleteProject}
                    isMobile={isMobile}
                  />
                );
              })}
          </DetailContent>
        </DetailArea>
      )} */}

      <DetailArea>
        {isMobile && (
          <SearchBar
            filteredData={filteredData}
            setInternalSearchResults={setInternalSearchResults}
            query={query}
            setQuery={setQuery}
            searchWidth="95%"
            internalSearch={true}
          />
        )}
        <ProjectsList
          filteredData={
            query && query !== "" ? internalSearchResults : filteredData
          }
          editFunc={editProject}
          deleteFunc={deleteProject}
          isMobile={isMobile}
        />
      </DetailArea>
      <AddProjectPopup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        data={project}
        setData={setProject}
        handleButtonFunc={addProject}
        isMobile={isMobile}
        institution_id={institution_id}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        isMobile={isMobile}
        headerText="You have exceeded the projects limit."
        subHeaderText={`You can add a maximum of ${projectsLimit} projects.`}
        showButton={true}
      />
    </DetailedFormContainer>
  );
};

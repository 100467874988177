import React, { useState, useEffect, useRef } from "react";
import { handleCommaFormat, numberWithCommas, removeComma } from "common/numberFormat";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  SmallTitleText
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import DateTimePickerField from "components/dateTimePickerField";
import { addChangeOrderBuyer } from "api/work_in_progress";

export const AddChangeOrderBuyerPopup = ({
  setOpenDialog,
  openDialog,
  data,
  setData,
  isMobile,
  label,
  buttonLabel,
}) => {
  const [changeOrderNo, setChangeOrderNo] = useState(data?.new_change_order_id);
  const [changeOrderDate, setChangeOrderDate] = useState("");
  const [changeInContractValue, setChangeInContractValue] = useState("");
  const [changeInContractTime, setChangeInContractTime] = useState(0);
  const [newEstimatedRevenue, setNewEstimatedRevenue] = useState(data?.project_value_or_estimated_revenue || 0);
  const [newContractCompletionDate, setNewContractCompletionDate] = useState(data?.new_contract_completion_date || "");
  const [dateApproved, setDateApproved] = useState("");
  const [validSubmit, setValidSubmit] = useState(false);
  const [remarks, setRemarks] = useState("");
  const oldTotalChangeInContractValue = data?.project_value_or_estimated_revenue ? removeComma(data?.project_value_or_estimated_revenue) : 0;
  const userData = JSON.parse(localStorage.getItem('User'));
  const userEmail = userData?.email;

  useEffect(() => {
    if (
      changeOrderNo?.length > 0 &&
      changeOrderDate?.length > 0 &&
      changeInContractTime?.length > 0 &&
      changeInContractValue?.length > 0 &&
      newContractCompletionDate?.length > 0
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [changeOrderNo, changeOrderDate, changeInContractTime, changeInContractValue, newContractCompletionDate]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["change_order_no"] = changeOrderNo;
    updatedData["change_order_date"] = changeOrderDate ? changeOrderDate : "";
    updatedData["change_in_contract_value"] = changeInContractValue ? removeComma(changeInContractValue) : 0;
    const totalChangeInContractValue = oldTotalChangeInContractValue + removeComma(changeInContractValue);
    updatedData["change_in_contract_time"] = changeInContractTime ? changeInContractTime : 0;
    updatedData["new_contract_completion_date"] = newContractCompletionDate ? newContractCompletionDate : 0;
    setNewEstimatedRevenue(totalChangeInContractValue);
    updatedData["new_estimated_revenue"] = totalChangeInContractValue;
    updatedData["date_approved"] = dateApproved;
    updatedData["remarks"] = remarks ? remarks : "";
    setData(updatedData);
  }, [
    changeOrderNo,
    changeOrderDate,
    changeInContractValue,
    newContractCompletionDate,
    newEstimatedRevenue,
    dateApproved,
    remarks
  ]);


  useEffect(() => {

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0');

    const currentDate = `${year}-${month}-${day}`;

    const completionDate = data?.contract_completion_date ? data?.contract_completion_date : currentDate;
    const changeInContractTimeNumber = parseInt(changeInContractTime, 10);

    try {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(completionDate)) {
        console.log('Invalid date format. Use YYYY-MM-DD');
      }
      const date = new Date(completionDate);
      date.setDate(date.getDate() + changeInContractTimeNumber);
      const formattedDate = date.toISOString().split('T')[0]; 
      setNewContractCompletionDate(formattedDate);
    } catch (error) {
      console.log("Opps!!!");
    }
  }, [changeInContractTime]);


  useEffect(() => {  
    const originalCompletionDate = data?.contract_completion_date ? data?.contract_completion_date : "";
    const newCompletionDate = newContractCompletionDate; 
  
    try {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(originalCompletionDate)) {
        console.log('Invalid original completion date format. Use YYYY-MM-DD');
      }
      if (!/^\d{4}-\d{2}-\d{2}$/.test(newCompletionDate)) {
        console.log('Invalid new completion date format. Use YYYY-MM-DD');
      }
  
      const originalDate = new Date(originalCompletionDate);
      const newDate = new Date(newCompletionDate);
  
      const timeDifference = newDate.getTime() - originalDate.getTime(); 
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); 
        
      if (isNaN(daysDifference)) {
        setChangeInContractTime(0);
        setNewContractCompletionDate(originalCompletionDate);
      } else {
        setChangeInContractTime(daysDifference);
      }


    } catch (error) {
      console.log("Oops!! An error occurred.");
    }
  }, [newContractCompletionDate]);


  const handleAddChangeOrderBuyer = () => {
    const change_order_no = changeOrderNo;
    const change_order_date = changeOrderDate ? changeOrderDate : "";
    const change_in_contract_value = changeInContractValue ? removeComma(changeInContractValue) : 0;
    const change_in_contract_time = changeInContractTime ? changeInContractTime : 0;
    const new_estimated_revenue = newEstimatedRevenue ? removeComma(newEstimatedRevenue) : 0;
    const new_contract_completion_date = newContractCompletionDate ? newContractCompletionDate : "";
    const date_approved = dateApproved;
    const newData = { ...data, change_order_no: change_order_no, change_order_date: change_order_date, change_in_contract_value: change_in_contract_value, change_in_contract_time: change_in_contract_time, new_contract_completion_date: new_contract_completion_date, new_estimated_revenue: new_estimated_revenue, date_approved: date_approved, remarks: remarks,  user_email: userEmail};
    addChangeOrderBuyer(newData, () => { }, () => { });

  };

  const handleClose = () => {
    setOpenDialog(false);
    setChangeOrderNo(data?.new_change_order_id);
    setChangeOrderDate("");
    setDateApproved("");
    setChangeInContractTime(0);
    setChangeInContractValue("");
    setNewEstimatedRevenue("");
    setNewContractCompletionDate("");
    setRemarks("");
  };


  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <StyledPaper elevation={0} isMobile={isMobile}>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
          <FormTitle style={{ marginTop: "10px", color: "#1A051D", fontSize: "22px", fontWeight: "600", fontStyle: "normal", lineHeight: "24px" }}>
            {label || "Change Order"}
          </FormTitle>
          <SmallTitleText style={{ marginBottom: 10 }}>Construction of a Warehouse</SmallTitleText>

          <InfoArea isMobile={isMobile} marginTop="0px">
            <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
              <Input
                id="inputWrap"
                label="Change Order #"
                type="text"
                placeholder="0"
                labelSize="12px"
                fontSize="18px"
                value={changeOrderNo}
                setValue={(value) => {
                  setChangeOrderNo(value.toLocaleString());
                }}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <DateTimePickerField
                showDateField={true}
                dateWidth="50%"
                paddingRight="0px"
                dateLabel="Change Order Date*"
                dateValue={changeOrderDate}
                setDateValue={setChangeOrderDate}
              />
            </div>

            <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
              <Input
                id="inputWrap"
                label="Change in Contract Value*"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={changeInContractValue}
                setValue={(value) => {
                  setChangeInContractValue(value.toLocaleString());
                }}
                onBlur={(e) => setChangeInContractValue(handleCommaFormat(e.target.value))}
                noCheckIcon={true}
                noErrorBorder={true}
              />

              <Input
                id="inputWrap last"
                label="Change in Contract Time(Days)*"
                type="text"
                placeholder="0"
                labelSize="12px"
                fontSize="18px"
                value={changeInContractTime}
                setValue={(value) => {
                  setChangeInContractTime(value.toLocaleString());
                }}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </div>

            <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
              <Input
                id="inputWrap"
                label="New Estimated Revenue"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(newEstimatedRevenue)}
                noCheckIcon={true}
                noErrorBorder={true}
                backgroundColor="rgba(235, 233, 241, 0.75)"
                disabled
              />

              <DateTimePickerField
                showDateField={true}
                dateWidth="50%"
                paddingRight="0px"
                dateLabel="New Contract Completion Date*"
                dateValue={newContractCompletionDate}
                setDateValue={setNewContractCompletionDate}
              />              
            </div>

            <div style={{ margin: "0px", display: "flex", flexDirection: "row", width: "100%" }}>
              <DateTimePickerField
                showDateField={true}
                dateWidth="50%"
                paddingRight="16px"
                dateLabel="Date Approved"
                dateValue={dateApproved}
                setDateValue={setDateApproved}
              />

              <Input
                id="inputWrap last"
                label="Remarks"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={remarks}
                setValue={setRemarks}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
              />

            </div>
          </InfoArea>

          <FunctionalButton
            disabled={validSubmit}
            width="30%"
            marginTop="32px"
            marginBottom="34x"
            marginRight="0px"
            handleButton={() => {
              handleAddChangeOrderBuyer();
              setOpenDialog(false);
              setChangeOrderNo("");
              setChangeOrderDate("");
              setChangeInContractValue("");
              setChangeInContractTime("");
              setNewEstimatedRevenue("");
              setNewContractCompletionDate("");
              setDateApproved("");
              setRemarks("");
            }}
            buttonTitle={buttonLabel || "APPROVE"}
          />
        </StyledPaper>
      </Dialog>
    </>
  );
};

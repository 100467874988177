import React from "react";

import { StyledCellTitle, StyledCellCommon, StyledCellAction } from "./styles";

import { ActionsMenu } from "./ActionsMenu";
import ConciseTable from "components/filesListTable/conciseTable";

export const AllClientsTable = ({
  data,
  setData,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
}) => {
  const body = (bodyData) => {
    return (
      <>
        <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
          {bodyData?.AccountId}
        </StyledCellTitle>
        {!isShortened && (
          <StyledCellCommon
            component={"tr"}
            scope="row"
            borderRadius={borderRadius}
          >
            {bodyData?.CompanyName}
          </StyledCellCommon>
        )}
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData.Address}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData?.count}
        </StyledCellCommon>
      </>
    );
  };

  const actions = (props) => {
    return (
      <>
        <StyledCellAction
          component={"tr"}
          scope="row"
          align="center"
          borderRadius={borderRadius}
        >
          <ActionsMenu
            client_id={props.AccountId}
            companyName={props.CompanyName}
            setData={setData}
          />
        </StyledCellAction>
      </>
    );
  };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Account ID", "20%"),
    createHeader("Company Name", "29%"),
    createHeader("Address", "27%"),
    createHeader("Underway Projects", "30%"),
    createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Account ID"),
    createHeader("Company Name"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
      <ConciseTable
        actions={actions}
        actionType="client"
        data={data}
        //height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { handleCommaFormat, numberWithCommas, removeComma } from "common/numberFormat";

import {
  InputLabel,
  CloseButton,
  Row,
  FormTitle,
  StyledFileName,
  StyledUploadIcon,
  InfoArea,
  StyledPaper,
  InfoLabel,
  SmallTitleText
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { AlertPopup } from "components/alertPopup/alertPopup";
import DateTimePickerField from "components/dateTimePickerField";
import { addProgressPaymentBuyer } from "api/work_in_progress";

export const AddPaymentProgressBuyerPopup = ({
  setOpenDialog,
  openDialog,
  data,
  setData,
  handleButtonFunc,
  isMobile,
  notShowResume,
  label,
  buttonLabel,
  noEmailEdit,
  fileLimitCount,
  fileLimitSizeMB,
}) => {
  const [validSubmit, setValidSubmit] = useState(false);
  const [progressPaymentNo, setProgressPaymentNo] = useState(data?.new_ppc_id);
  const [progressPaymentDate, setProgressPaymentDate] = useState("");
  const [dateApproved, setDateApproved] = useState("");
  const [billingsToDate, setBillingsToDate] = useState("");
  const [holdbackRetainageToDate, setHoldbackRetainageToDate] = useState("");
  const [previousProgressPayments, setPreviousProgressPayments] = useState(0);
  const [currentPaymentDue, setCurrentPaymentDue] = useState("");
  const [remarks, setRemarks] = useState("");
  const lastPreviousProgressPayments = data?.last_previous_progress_payments ? removeComma(data?.last_previous_progress_payments) : 0;
  const userData = JSON.parse(localStorage.getItem('User'));
  const userEmail = userData?.email;

  useEffect(() => {
    if (
      progressPaymentNo?.length > 0 &&
      progressPaymentDate?.length > 0 &&
      billingsToDate?.length > 0 &&
      holdbackRetainageToDate?.length > 0
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [progressPaymentNo, progressPaymentDate, billingsToDate, holdbackRetainageToDate]);



  useEffect(() => {
    const updatedData = { ...data };
    updatedData["progress_payment_no"] = progressPaymentNo;
    updatedData["billings_to_date"] = billingsToDate ? removeComma(billingsToDate) : 0;
    updatedData["holdback_retainage_to_date"] = holdbackRetainageToDate ? removeComma(holdbackRetainageToDate) : 0;
    const diffPreviousProgressPayments = removeComma(billingsToDate) - removeComma(holdbackRetainageToDate);
    setPreviousProgressPayments(diffPreviousProgressPayments);
    const diffCurrentPaymentDue = diffPreviousProgressPayments - lastPreviousProgressPayments
    setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));
    updatedData["current_payment_due"] = currentPaymentDue ? currentPaymentDue : 0;
    updatedData["progress_payment_date"] = progressPaymentDate;
    updatedData["date_approved"] = dateApproved;
    updatedData["remarks"] = remarks ? remarks : "";
    setData(updatedData);
  }, [
    progressPaymentNo,
    billingsToDate,
    holdbackRetainageToDate,
    currentPaymentDue,
    previousProgressPayments,
    progressPaymentDate,
    dateApproved,
    remarks
  ]);

  const handleAddProgressPaymentBuyer = () => {
    const ppc_status = "Approved"
    const progress_payment_no = progressPaymentNo;
    const billings_to_date = billingsToDate ? removeComma(billingsToDate) : 0;
    const holdback_retainage_to_date = holdbackRetainageToDate ? removeComma(holdbackRetainageToDate) : 0;
    const progress_payment_date = progressPaymentDate;
    const date_approved = dateApproved;
    const newData = { ...data, progress_payment_no: progress_payment_no, billings_to_date: billings_to_date, holdback_retainage_to_date: holdback_retainage_to_date, progress_payment_date: progress_payment_date, date_approved: date_approved, remarks: remarks, user_email: userEmail, ppc_status: ppc_status };
    addProgressPaymentBuyer(newData, () => { }, () => { });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };


  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <StyledPaper elevation={0} isMobile={isMobile}>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
          <FormTitle style={{ marginTop: "10px", color: "#1A051D", fontSize: "22px", fontWeight: "600", fontStyle: "normal", lineHeight: "24px" }}>
            {label || "Progress Payment"}
          </FormTitle>
          <SmallTitleText style={{ marginBottom: 10 }}>Construction of a Warehouse</SmallTitleText>

          <InfoArea isMobile={isMobile} marginTop="0px">

            <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
              {data?.ppc_status === "Approved" && (
                <>
                  <Input
                    id="inputWrap"
                    label="Progress Payment #"
                    type="text"
                    placeholder=""
                    labelSize="12px"
                    fontSize="18px"
                    value={data?.progress_payment_no}
                    noCheckIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />
                  <DateTimePickerField
                    showDateField={true}
                    dateWidth="50%"
                    paddingRight="0px"
                    dateLabel="Progress Payment Date"
                    dateValue={data?.progress_payment_date ? data?.progress_payment_date : ""}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />
                </>
              )}


              {data?.ppc_status !== "Approved" && (
                <>
                  <Input
                    id="inputWrap"
                    label="Progress Payment #"
                    type="text"
                    placeholder=""
                    labelSize="12px"
                    fontSize="18px"
                    value={progressPaymentNo}
                    setValue={setProgressPaymentNo}
                    validateFunc={(item) => item.length > 0}
                    noCheckIcon={true}
                    noErrorBorder={true}
                  />
                  <DateTimePickerField
                    showDateField={true}
                    dateWidth="50%"
                    paddingRight="16px"
                    dateLabel="Progress Payment Date*"
                    dateValue={progressPaymentDate}
                    setDateValue={setProgressPaymentDate}
                  />
                </>
              )}

            </div>

            <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
              {data?.ppc_status === "Approved" && (
                <>
                  <Input
                    id="inputWrap"
                    label="Billings to Date"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={handleCommaFormat(data?.billings_to_date)}
                    noCheckIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />

                  <Input
                    id="inputWrap last"
                    label="Holdback/Retainage to Date"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={handleCommaFormat(data?.holdback_retainage_to_date)}
                    noCheckIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />
                </>
              )}


              {data?.ppc_status !== "Approved" && (
                <>
                  <Input
                    id="inputWrap"
                    label="Billings to Date*"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={billingsToDate}
                    setValue={(value) => {
                      setBillingsToDate(value.toLocaleString());
                    }}
                    onBlur={(e) => setBillingsToDate(handleCommaFormat(e.target.value))}
                    noCheckIcon={true}
                    noErrorBorder={true}
                  />

                  <Input
                    id="inputWrap last"
                    label="Holdback/Retainage to Date*"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={holdbackRetainageToDate}
                    setValue={(value) => {
                      setHoldbackRetainageToDate(value.toLocaleString());
                    }}
                    onBlur={(e) => setHoldbackRetainageToDate(handleCommaFormat(e.target.value))}
                    noCheckIcon={true}
                    noErrorBorder={true}
                  />
                </>
              )}
            </div>

            <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>

              {data?.ppc_status === "Approved" && (
                <>
                  <Input
                    id="inputWrap"
                    label="Previous Progress Payments"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={handleCommaFormat(data?.last_previous_progress_payments)}
                    noCheckIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />
                  <Input
                    id="inputWrap last"
                    label="Current Payment Due"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={handleCommaFormat(data?.current_payment_due)}
                    noCheckIcon={true}
                    noCancelIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />
                </>
              )}


              {data?.ppc_status !== "Approved" && (
                <>

                  <Input
                    id="inputWrap"
                    label="Previous Progress Payments"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={handleCommaFormat(lastPreviousProgressPayments)}
                    noCheckIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />

                  <Input
                    id="inputWrap last"
                    label="Current Payment Due"
                    type="text"
                    placeholder="$"
                    labelSize="12px"
                    fontSize="18px"
                    value={handleCommaFormat(currentPaymentDue)}
                    noCheckIcon={true}
                    noCancelIcon={true}
                    noErrorBorder={true}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />
                </>
              )}

            </div>

            <div style={{ margin: "0px", display: "flex", flexDirection: "row", width: "100%" }}>

              {data?.ppc_status !== "Approved" && (
                <>
                  <DateTimePickerField
                    showDateField={true}
                    dateWidth="50%"
                    paddingRight="16px"
                    dateLabel="Date Approved"
                    dateValue={dateApproved}
                    setDateValue={setDateApproved}
                  />

                  <Input
                    id="inputWrap last"
                    label="Remarks"
                    type="text"
                    placeholder=""
                    labelSize="12px"
                    fontSize="18px"
                    value={remarks}
                    setValue={setRemarks}
                    noCheckIcon={true}
                    noCancelIcon={true}
                    noErrorBorder={true}
                  />

                </>
              )}


              {data?.ppc_status === "Approved" && (
                <>
                  <DateTimePickerField
                    showDateField={true}
                    dateWidth="50%"
                    paddingRight="16px"
                    dateLabel="Date Approved"
                    dateValue={data?.ppc_date_approved ? data?.ppc_date_approved : ""}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                  />

                  <Input
                    id="inputWrap last"
                    label="Remarks"
                    type="text"
                    placeholder=""
                    labelSize="12px"
                    fontSize="18px"
                    value={data?.ppc_remarks ? data?.ppc_remarks : ""}
                    backgroundColor="rgba(235, 233, 241, 0.75)"
                    disabled
                    noCheckIcon={true}
                    noCancelIcon={true}
                    noErrorBorder={true}
                  />

                </>
              )}



            </div>
          </InfoArea>

          {data?.ppc_status === "Approved" && (
            <>
              <FunctionalButton
                disabled
                width="30%"
                marginTop="32px"
                marginBottom="34x"
                marginRight="0px"
                handleButton={() => {
                  handleAddProgressPaymentBuyer();
                  setOpenDialog(false);
                  setDateApproved("");
                  setRemarks("");
                }}
                buttonTitle={buttonLabel || "APPROVED"}
              />
            </>
          )}

          {data?.ppc_status !== "Approved" && (
            <>
              <FunctionalButton
                disabled={validSubmit}
                width="30%"
                marginTop="32px"
                marginBottom="34x"
                marginRight="0px"
                handleButton={() => {
                  handleAddProgressPaymentBuyer();
                  setOpenDialog(false);
                  setDateApproved("");
                  setRemarks("");
                }}
                buttonTitle={buttonLabel || "APPROVE"}
              />
            </>
          )}

        </StyledPaper>
      </Dialog>
    </>
  );
};

import React, { useEffect, useState } from "react";
import {
  StyledChartContainer,
  StyledGraphContainer,
  StyledInnerContainer,
  StyledOutsideContainer,
  StyledTitleContainer,
  StyledValueContainer,
} from "../styles";
import DonutChartComponent from "./donutChartComponent";
import { numberWithCommas } from "common/numberFormat";

const ChartComponent = ({
  marginRight,
  title = " ",
  subTitle1 = " ",
  subTitle2 = " ",
  value1,
  value2,
}) => {
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);

  useEffect(() => {
    if (value2 > 0) {
      const earnedRevenue = ((value2 / value1) * 100).toFixed(0);
      const remaining = 100 - earnedRevenue;
      setPercentage1(earnedRevenue);
      setPercentage2(remaining);
    }
  }, [value1, value2]);

  return (
    <StyledGraphContainer marginRight={marginRight}>
      <StyledOutsideContainer>
        <StyledInnerContainer>
          <StyledTitleContainer>{title}</StyledTitleContainer>
          <StyledChartContainer>
            <DonutChartComponent
              percentage1={percentage1}
              percentage2={percentage2}
            />
          </StyledChartContainer>
        </StyledInnerContainer>
        <StyledInnerContainer
          style={{
            alignItems: "center",
            gap: 11,
          }}
        >
          <StyledInnerContainer
            style={{
              alignItems: "center",
            }}
          >
            <div>
              <StyledValueContainer color="#6E6B7B">$</StyledValueContainer>
              <StyledValueContainer color="#1A051D">
                {numberWithCommas(value1) || 0}
              </StyledValueContainer>
            </div>
            <StyledTitleContainer
              color="#888888"
              fontSize="11px"
              fontWeight="400"
            >
              {subTitle1}
            </StyledTitleContainer>
          </StyledInnerContainer>
          <StyledInnerContainer
            style={{
              alignItems: "center",
            }}
          >
            <div>
              <StyledValueContainer color="#FFAA7B">$</StyledValueContainer>
              <StyledValueContainer color="#FF6D1D">
                {numberWithCommas(value2) || 0}
              </StyledValueContainer>
            </div>
            <StyledTitleContainer
              color="#888888"
              fontSize="11px"
              fontWeight="400"
            >
              {subTitle2}
            </StyledTitleContainer>
          </StyledInnerContainer>
        </StyledInnerContainer>
      </StyledOutsideContainer>
    </StyledGraphContainer>
  );
};

export default ChartComponent;

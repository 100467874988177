import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { DetailedFormContainer } from "pages/contractorDashboard/styles.js";
import { LargeText, SetupContainer, InputLabel, StyledFileName, StyledUploadIcon, ErrorText} from "../styles.js";
import { getActiveUsers } from "../../../api/external_data";
import updateObjectByPath from "../../../common/updateObjectByPath";
import Select from "../../../components/selectField";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddResumeButton from "../../../assets/onBoarding/AddResumeButton";
import { COLORS } from "../../../constants";
import { FunctionalButton } from '../../../components/functionalButton/index';
import { onboardingUpload } from '../../../api/onboarding';
import { SuccessPopup } from "./SuccessPopup";


export default function Upload({ noWidthChange }) {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [data, setData] = useState({});
  const [dialogRes, setDialogRes] = useState("");
  const [openProgress, setOpenProgress] = useState(false);
  const fileInput = useRef();
  const [array, setArray] = useState([]);
  const fileReader = new FileReader();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [messageText, setMessageText] = useState('');

  const fileOptions = [
    "Projects",
    "Personnel",
    "Users"
  ];


  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows?.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    onChangeData("uploadedData", array)
  };

  const handleFileSelect = () => {
    fileInput.current.click();
  };

  const handleUploadFile = async (file) => {
    setOpenProgress(true);

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
      setOpenProgress(false);
    }
  };


  const handleSubmit = () => {

    onboardingUpload(
      data,
      () => {
        setOpenSuccessPopup(true);
      },
      (err) => { 
        setHasError(true);
        setMessageText(err.data.message);
      }
    );

  };


  useEffect(() => {
    setHasError(false);
  }, [])

  return (
    <SetupContainer isMobile={isMobile}>
      <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "Upload Utility"
            : "File Upload Utility"}
        </LargeText>

        {hasError && (
          <ErrorText>{messageText}</ErrorText>
        )}


        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Select Upload File Type"
            options={fileOptions?.map((item) => ({
              value: item,
              label: item,
            }))}
            value={data?.upload_file_type}
            setValue={(val) => {
              onChangeData("upload_file_type", val);
            }}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>


        <div className="fullWidthInputWrap">
          <InstitutionDropdown data={data} onChangeData={onChangeData} />
        </div>


        <div className="fullWidthInputWrap">
          <UserEmailDropdown data={data} onChangeData={onChangeData} />
        </div>

        <div className="fullWidthInputWrap">

          <InputLabel>Upload File (Only CSV)</InputLabel>
          <div
            style={{ width: "100%", display: "flex", paddingRight: "0px" }}
          >
            <StyledUploadIcon>
              <input
                ref={fileInput}
                id="resume-selector"
                onClick={(e) => {
                  e.target.value = null;
                  handleFileSelect();
                }}
                onChange={(e) => {
                  setDialogRes(e.target.files[0]);
                  handleUploadFile(e.target.files[0]);
                }}
                type="file"
                style={{ display: "none" }}
              />
              <AddResumeButton />
            </StyledUploadIcon>
            {dialogRes && (
              <StyledFileName>
                <span>{dialogRes.name}</span>
                {openProgress && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      color: COLORS.primaryOrange,
                    }}
                    disableShrink
                  />
                )}
                {!openProgress && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setDialogRes(null);
                      setArray([]);
                      onChangeData("uploadedData", array)
                    }}
                  >
                    <CloseIcon />{" "}
                  </span>
                )}
              </StyledFileName>
            )}
          </div>

        </div>
        <FunctionalButton
          buttonTitle="Submit"
          handleButton={handleSubmit}
          width="150px"
          height="38px"
          marginLeft="350px"
          marginTop="20px"
        />

      </DetailedFormContainer>
      <SuccessPopup
        setOpenPopup={setOpenSuccessPopup}
        openPopup={openSuccessPopup}
        isMobile={isMobile}
      />

    </SetupContainer>
  );
}



const InstitutionDropdown = ({ data, onChangeData }) => {
  const [activeUsers, setActiveUsers] = useState([]);


  useEffect(() => {
    getActiveUsers()
      .then((res) => {
        setActiveUsers(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);



  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Select Institution Name"
      options={activeUsers?.map((item) => ({
        value: item.institution,
        label: item.institution,
      }))}
      value={data?.institution}
      setValue={(val) => onChangeData("institution", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const UserEmailDropdown = ({ data, onChangeData }) => {
  const [activeUsers, setActiveUsers] = useState([]);


  useEffect(() => {
    getActiveUsers()
      .then((res) => {
        setActiveUsers(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);



  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Select User's Email"
      options={activeUsers?.map((item) => ({
        value: item.email,
        label: item.email,
      }))}
      value={data?.email}
      setValue={(val) => onChangeData("email", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};


import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { AddCorporatePopup } from "../../../components/addCorporatePopup";
import { getCorporates } from "../../../api/external_data";
import { StyledProjectSelectionContainer, HeaderArea } from '../styles';
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";


export default function DataMaintananceCorporate(editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Id',width:"20%" },
    { title: 'Underwriter Name',width:"40%" },
    { title: 'Plan Name : Number of Plan',width:"40%" },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(corporates);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getCorporates()
      .then((res) => {
        setCorporates(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="40%">
          {bodyData.corporate_name}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="40%">
          {Array.isArray(bodyData.corporate_packages)
            ?
            bodyData?.corporate_packages?.map((item) => (
              <p> Basic : {item.Basic}</p>
            ))
            : null}

          {Array.isArray(bodyData.corporate_packages)
            ?
            bodyData?.corporate_packages?.map((item) => (
              <p> Premium : {item.Premium}</p>
            ))
            : null}
        </StyledTableCell>

      </>

    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <HeaderArea width="100%">
        <SearchBar
          filteredData={corporates}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          internalSearch={true}
        />
        <FunctionalButton
          buttonTitle="Add Underwriter"
          handleButton={handleAdd}
          width="60%"
        />
      </HeaderArea>

      <ConciseTable headers={isMobile ? "" : headers} />
      <ConciseTable
        length={isMobile ? 7 : 7.5}
        body={body}
        data={
          query && query !== "" ? internalSearchResults : corporates
        }
      />

      <AddCorporatePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
      />

    </StyledProjectSelectionContainer>
  )
}



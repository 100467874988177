import React, { useEffect } from "react";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";

import { RadioButton } from "../radioButton";
import { ReactComponent as ViewIcon } from "../../assets/common/ViewIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/common/downloadIcon.svg";

import { RoundedCheckbox } from "../roundedCheckbox";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

import classes from "./List.module.css";
import { COLORS, FONT_SIZES } from "../../constants";
import DateTimePickerField from "components/dateTimePickerField";

export const SimpleList = ({
  columns,
  data,
  emptyListText,
  maxHeight,
  onInteraction,
  readOnly,
  setData,
  disabled,
}) => {
  if (columns && data && data.length > 0) {
    return (
      <>
        <div className={[classes.row, classes["header-row"]].join(" ")}>
          {columns.map((column, index) => (
            <div
              key={column.id}
              className={[classes.item, classes["list-header"]].join(" ")}
              style={{
                width: column.width || `${100 / columns.length}%`,
                fontSize: FONT_SIZES.body_4,
                color: COLORS.textGrey,
                textAlign:
                  column.icon ||
                  (column.fieldType && ["select"].includes(column.fieldType))
                    ? "center"
                    : "left",
              }}
            >
              {column.icon ? <></> : column.title}
            </div>
          ))}
        </div>
        <div
          className={classes["list-container"]}
          style={{
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
            position: "relative",
            overflow: "auto",
            maxHeight: maxHeight ? maxHeight : "",
          }}
        >
          {data.map((row, rowIndex) => (
            <div
              key={row.id}
              className={[classes.row, classes["item-row"]].join(" ")}
              style={{ border: `1px solid ${COLORS.greyBorder}` }}
            >
              {columns.map((column, columnIndex) => (
                <div
                  key={column.id}
                  className={[classes.item, classes["item-column"]].join(" ")}
                  style={{
                    width: column.width || `${100 / columns.length}%`,
                    fontSize: FONT_SIZES.body_5,
                  }}
                >
                  {column.icon && (
                    <>
                      {column.icon === "visibility" && (
                        <div
                          className={classes["icon-container"]}
                          style={{ color: COLORS.textGrey }}
                          onClick={() =>
                            onInteraction &&
                            onInteraction({
                              columnId: column.id,
                              index: rowIndex,
                              rowId: row.id,
                            })
                          }
                        >
                          <VisibilityIcon sx={{ color: COLORS.textGrey }} />
                        </div>
                      )}
                      {column.icon === "details" && (
                        <div
                          className={classes["icon-container"]}
                          style={{ color: COLORS.textGrey }}
                          onClick={() =>
                            onInteraction &&
                            onInteraction({
                              columnId: column.id,
                              index: rowIndex,
                              rowId: row.id || row.file_id,
                              name: row.name || "",
                            })
                          }
                        >
                          <ViewIcon />
                        </div>
                      )}
                      {column.icon === "download" && (
                        <div
                          className={classes["icon-container"]}
                          style={{ color: COLORS.textGrey }}
                          onClick={() =>
                            onInteraction &&
                            onInteraction({
                              columnId: column.id,
                              index: rowIndex,
                              rowId: row.id || row.file_id,
                              name: row.name || "",
                            })
                          }
                        >
                          <DownloadIcon />
                        </div>
                      )}
                    </>
                  )}
                  {column.fieldType === "select" &&
                    column.selectedId in row && (
                      <div className={classes["select-container"]}>
                        {column.fieldSubtype === "checkbox" && (
                          <RoundedCheckbox
                            attributeTypes={[true]}
                            onChange={() =>
                              setData &&
                              !readOnly &&
                              setData({
                                columnId: column.id,
                                index: rowIndex,
                                rowId: row.id,
                                selectedId: column.selectedId || "selected",
                                value:
                                  (column.selectedId
                                    ? row[column.selectedId]
                                    : row.selected) ||
                                  (column.selectedId
                                    ? row[column.selectedId]
                                    : row.selected) === false
                                    ? !(column.selectedId
                                        ? row[column.selectedId]
                                        : row.selected)
                                    : true,
                              })
                            }
                            readOnly={readOnly}
                            value={
                              column.selectedId
                                ? row[column.selectedId]
                                : row.selected || null
                            }
                            disabled={disabled}
                          />
                        )}
                        {column.fieldSubtype === "radioButton" && (
                          <RadioButton
                            checkedIcon={
                              <CheckedRadioButton
                                color={readOnly ? COLORS.textGrey : null}
                              />
                            }
                            handleChange={() =>
                              setData &&
                              !readOnly &&
                              setData({
                                columnId: column.id,
                                index: rowIndex,
                                rowId: row.id,
                                selectedId: column.selectedId || "selected",
                                value: column.allowUncheck
                                  ? (column.selectedId
                                      ? row[column.selectedId]
                                      : row.selected) ||
                                    (column.selectedId
                                      ? row[column.selectedId]
                                      : row.selected) === false
                                    ? !(column.selectedId
                                        ? row[column.selectedId]
                                        : row.selected)
                                    : true
                                  : true,
                              })
                            }
                            isChecked={
                              column.selectedId
                                ? row[column.selectedId]
                                : row.selected || false
                            }
                            readOnly={readOnly}
                            unCheckedIcon={
                              <UncheckedRadioButton
                                color={readOnly ? COLORS.textGrey : null}
                              />
                            }
                            value={column.id}
                          />
                        )}
                      </div>
                    )}
                  {column.fieldType === "boolean" && (
                    <span
                      className={[
                        !readOnly && classes[column.fieldSubtype],
                        classes[
                          column.id in row
                            ? row[column.id]
                              ? "positive"
                              : "negative"
                            : classes[column.fieldSubtype]
                        ],
                      ].join(" ")}
                    >
                      {column.id in row
                        ? row[column.id]
                          ? column.booleanTrueText || "YES"
                          : column.booleanFalseText || "NO"
                        : "N/A"}
                    </span>
                  )}
                  {!column.icon && !column.fieldType ? (
                    column.id_secondary ? (
                      <div>
                        <div>{row[column.id]}</div>
                        <div
                          className={classes["secondary-data"]}
                          style={{ fontSize: FONT_SIZES.body_6 }}
                        >
                          {row[column.id_secondary]}
                        </div>
                      </div>
                    ) : (
                      row[column.id]
                    )
                  ) : (
                    <></>
                  )}
                  {column.fieldType === "date" && (
                    <div>
                      <DateTimePickerField
                        showDateField={true}
                        withoutMargin={true}
                        dateValue={row[column.id] ? row[column.id] : ""}
                        readOnly={true}
                        paddingRight="0px"
                        fontColor="black"
                        fontSize="14px"
                      />
                    </div>
                  )}
                  {column.fieldType === "tile" && (
                    <div
                      className={[
                        !readOnly && classes["tile"],
                        // classes["tile" + row[column.id]],
                      ].join(" ")}
                    >
                      <span>
                        Coming soon...
                        {/* {row[column.id] === 1
                          ? "HIGH"
                          : row[column.id] === 2
                          ? "MEDIUM"
                          : row[column.id] === 3
                          ? "LOW"
                          : "N/A"} */}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </>
    );
  } else {
    return (
      <div
        className={classes["list-container"]}
        style={{
          color: "black",
          fontSize: FONT_SIZES.body_4,
          fontWeight: "400",
        }}
      >
        <div
          className={classes["empty-list"]}
          style={{ color: COLORS.textGrey, fontSize: FONT_SIZES.body_5 }}
        >
          {emptyListText || "Empty List"}
        </div>
      </div>
    );
  }
};

import React from "react";
import bonds from "./bondsIcon.svg";
import styled from "styled-components";

const BondsIcon = ({ width, height, isMobile }) => {
  return (
    <StyledImg
      width={width || "106"}
      height={height || "112"}
      src={bonds}
      isMobile={isMobile}
    />
  );
};

export default BondsIcon;

const StyledImg = styled.img`
  ${(props) => (props.isMobile ? "margin-bottom: 4px; margin-top: 8px;" : "")}
`;

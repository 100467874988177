import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { GoBackButton } from "pages/buyers/components/goBackButton";
import { clientProjectsWipSelector } from "data/selectors/underwriters";
import { PageHeader } from "./components/pageHeader";
import { StyledContainer, StyledMainContainer } from "./styles";

import {
  StyledSubHeaderContent,
  StyledBox,
  PlaceholderText,
} from "./components/styles";
import { AllProjectsTable } from "./components/allProjectsTable";

import LeftComponent from "./components/leftComponent";
import RightComponent from "./components/rightComponent";
import { getLimitValues } from "api/work_in_progress";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";

const WorkInProgress = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();
  const [estimatedCostOfRevenue, setEstimatedCostOfRevenue] = useState(0);

  const [totalcostIncurredToDate, setTotalCostIncurredToDate] = useState(0);
  const companyName = location.state?.company_name || "";
  const accountId = location.state?.account_id;
  const clientProjects = useSelector(clientProjectsWipSelector);
  let history = useHistory();
  const [data, setData] = useState([]);

  const [limitValues, setLimitValues] = useState({});
  useEffect(() => {
    dispatch(
      getClientProjectsInfoForWip({ account_id: accountId }, "underway")
    );

    getLimitValues(
      { account_id: accountId },
      (res) => {
        setLimitValues(res);
      },
      () => {}
    );
  }, []);
  useEffect(() => {
    if (clientProjects) {
      setData(clientProjects);
    }
  }, [clientProjects]);
  const handleGoBack = () => {
    history.push("/underwriter-dashboard");
  };

  useEffect(() => {
    const totalEstimatedCostOfRevenue = data?.reduce((total, obj) => {
      // add  original value of the main object
      total += parseFloat(obj?.original_estimated_cost_of_revenue);
      let changeInContractCost = obj?.co_data?.reduce((totalChange, coData) => {
        // Sum up all estimated_cost_for_the_change in co_data array
        return totalChange + parseFloat(coData?.estimated_cost_for_the_change);
      }, 0);

      total += changeInContractCost;

      return total;
    }, 0);
    const total_cost_incurred_to_date = data?.reduce((total, item) => {
      const costForItem =
        parseFloat(item?.ppc_data[0]?.cost_incurred_to_date) || 0;

      return total + costForItem;
    }, 0);

    setTotalCostIncurredToDate(total_cost_incurred_to_date);
    setEstimatedCostOfRevenue(Math.round(totalEstimatedCostOfRevenue));
  }, [data]);

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <PageHeader
        subTitle="Work in Progress Schedule"
        companyName={companyName}
        hasButton={true}
        // handleButton={handleStartNew}
      />

      <StyledMainContainer>
        <LeftComponent
          data={limitValues}
          accountId={accountId}
          totalCostIncurredToDate={totalcostIncurredToDate}
          totalEstimatedCostOfRevenue={estimatedCostOfRevenue}
        />
        <RightComponent
          data={data}
          totalCostIncurredToDate={totalcostIncurredToDate}
          totalEstimatedCostOfRevenue={estimatedCostOfRevenue}
        />
      </StyledMainContainer>
      <div
        style={{
          height: "300px",
          width: "100%",
          marginTop: "30px",
        }}
      >
        <StyledSubHeaderContent>List of Projects</StyledSubHeaderContent>
        {!(data?.length > 0) && (
          <StyledBox boxHeight="80%" borderRadius="10px" margin="10px 0px">
            <PlaceholderText>
              Underway Projects will appear here
            </PlaceholderText>
          </StyledBox>
        )}
        {data?.length > 0 && (
          <AllProjectsTable
            data={data}
            setData={setData}
            clientCompanyName={companyName}
            companyId={accountId}
          />
        )}
      </div>
    </StyledContainer>
  );
};

export default WorkInProgress;

import axios from 'axios';
import { API_HOST } from '../constants';


let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };}
};

export const filterUsers = async (query) => {
  getAuth();
  return await axios.get(`${API_HOST}/user_management/users?filter=${query}`, {headers})
                    .then((res) => res.data)
                    .catch((err) => {
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                    });
};


export const updateRole = async (id, role, onSuccess, index) => {
  getAuth();

  if((id === null) || !role) {
    return;
  }

  const data = { user: id, role: role };

  return await axios.post(`${API_HOST}/user_management/update_role`, data, {headers})
                    .then((res) => {
                      if(onSuccess && index !== null) {
                        onSuccess(res.data, index);
                      }
                      return res.data;
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                    });
};


export const updateClassification = async (id, classification, onSuccess, index) => {
  getAuth();

  if((id === null) || !classification) {
    return;
  }

  const data = { user: id, clss: classification };

  return await axios.post(`${API_HOST}/user_management/update_class`, data, {headers})
                    .then((res) => {
                        if(onSuccess && index !== null) {
                          onSuccess(res.data, index);
                        }
                        return res.data;
                      }
                    )
                    .catch((err) => {
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                    });
};

export const addNewPledgxUser = async (data, onSuccess) => {
  getAuth();

  return await axios.post(`${API_HOST}/user_management/add_user`, data, {headers})
                    .then((res) => {
                        if(onSuccess) {
                          onSuccess();
                        }
                        return res.data;
                      }
                    )
                    .catch((err) => {
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                    });
};

export const editNewPledgxUser = async (data, onSuccess) => {
  getAuth();

  return await axios.post(`${API_HOST}/user_management/edit_user`, data, {headers})
                    .then((res) => {
                        if(onSuccess) {
                          onSuccess();
                        }
                        return res.data;
                      }
                    )
                    .catch((err) => {
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                    });
};


export const viewPledgxUserFromResearchDatabase = async (token1, token2, onSuccess, onFailure) => {
  headers['Content-Type'] = "application/json"
  return await axios.post(`${API_HOST}/register/confirm/${token1}/${token2}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
}

export const createPledgxUserFromResearchDatabase = async (data, onSuccess, onFailure) => {
  return await axios
    .post(`${API_HOST}/research/database/user/register/confirm`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

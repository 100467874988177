import React from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledTab, SetupContainer } from '../styles';
import UserOnboardingCreate from './UserOnboardingCreate';
import UserOnboardingEdit from './UserOnboardingEdit';
import AddUserInExistingClient from './AddUserInExistingClient'

export const UserOnboarding = () => {
  const [selectTab, setSelectTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  return (
    <SetupContainer>
      <TabContext value={selectTab}>

        <TabList onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{hidden:true}}>
          <StyledTab label="Create New Client" value="1" />
          <StyledTab label="Edit Existing Client" value="2" />
          <StyledTab label="Add User to Existing Client" value="3" />
        </TabList>

        <TabPanel value="1">
          <UserOnboardingCreate />
        </TabPanel>

        <TabPanel value="2">
          <UserOnboardingEdit />
        </TabPanel>

        <TabPanel value="3">
          <AddUserInExistingClient />
        </TabPanel>

      </TabContext>
    </SetupContainer>

  );
}

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  StyledButtonArea,
  StyledContainerInside,
  StyledContainerTitle,
  StyledHeaderContent,
  StyledSubHeaderContent,
} from "./styles";
import { FunctionalButton } from "components/functionalButton";
import { ExportIcon } from "assets/common/ExportIcon";
import { ImportIcon } from "assets/common/ImportIcon";
import { AddPaymentProgressContractorPopup } from "components/addPaymentProgressContractorPopup";
import { AddChangeOrderContractorPopup } from "components/addChangeOrderContractorPopup";
import { getWorkInProgress } from "api/work_in_progress";

export const PageHeader = ({
  subTitle,
  hasButton,
  handleButton,
  marginBottom,
  companyName,
  project,
  data,
  companyId,
}) => {
  const [
    openPaymentProgressContractorPopup,
    setOpenPaymentProgressContractorPopup,
  ] = useState(false);
  const [openChangeOrderContractorPopup, setOpenChangeOrderContractorPopup] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [paymentProgressContractor, setPaymentProgressContractor] = useState({});
  const [changeOrderContractor, setChangeOrderContractor] = useState({});
  const [changeOrderId, setChangeOrderId] = useState(0);
  const [ppcId, setPpcId] = useState(0);
  const [contractCompletionDate, setContractCompletionDate] = useState("");

  const handlePaymentProgressContractorAdd = () => {
    getWorkInProgress(data, onSuccessPaymentProgressContractorAdd, () => { });
  };

  const onSuccessPaymentProgressContractorAdd = (res) => {
    setPaymentProgressContractor(res);
    setPpcId(res?.new_ppc_id)
    setOpenPaymentProgressContractorPopup(true);
  };

  const handleChangeOrderContractorAdd = () => {
    getWorkInProgress(data, onSuccessChangeOrderContractorAdd, () => { });
  };


  const onSuccessChangeOrderContractorAdd = (res) => {
    setChangeOrderId(res?.new_change_order_id);
    setContractCompletionDate(res?.contract_completion_date);
    setChangeOrderContractor(res);
    setOpenChangeOrderContractorPopup(true);
  };

  return (
    <StyledContainerTitle>
      <StyledContainerInside>
        <StyledHeaderContent marginBottom={marginBottom}>
          {companyName}
        </StyledHeaderContent>
        {subTitle && (
          <StyledSubHeaderContent>{subTitle}</StyledSubHeaderContent>
        )}
      </StyledContainerInside>
      {hasButton && !project && (
        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="IMPORT"
              handleButton={() => { }}
              width="200px"
              height="48px"
              isReversedColor={true}
              frontIcon={true}
              disabled={true}
              icon={<ImportIcon marginRight="16px" />}
            />
            <FunctionalButton
              buttonTitle="EXPORT"
              handleButton={() => { }}
              width="200px"
              height="48px"
              marginRight="0"
              isReversedColor={true}
              frontIcon={true}
              disabled={true}
              icon={<ExportIcon marginRight="16px" />}
            />
          </StyledButtonArea>
        </>
      )}
      {hasButton && project && (
        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="PROGRESS PAYMENT "
              handleButton={handlePaymentProgressContractorAdd}
              width="215px"
              height="48px"
              fontSize="16px"
              isReversedColor={true}
            />
            <FunctionalButton
              buttonTitle="CHANGE ORDER"
              handleButton={handleChangeOrderContractorAdd}
              width="170px"
              height="48px"
              isReversedColor={true}
            />
          </StyledButtonArea>
        </>
      )}

      <AddPaymentProgressContractorPopup
        setOpenDialog={setOpenPaymentProgressContractorPopup}
        openDialog={openPaymentProgressContractorPopup}
        data={paymentProgressContractor}
        setData={setPaymentProgressContractor}
        projectData={data}
        ppcId={ppcId}
        isMobile={isMobile}
        companyId={companyId}
      />

      <AddChangeOrderContractorPopup
        setOpenDialog={setOpenChangeOrderContractorPopup}
        openDialog={openChangeOrderContractorPopup}
        data={changeOrderContractor}
        setData={setChangeOrderContractor}
        projectData={data}
        changeOrderId={changeOrderId}
        oldContractCompletionDate={contractCompletionDate}
        isMobile={isMobile}
        companyId={companyId}
      />
    </StyledContainerTitle>
  );
};

import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "./styles";
import { useMediaQuery } from "react-responsive";
import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import ProjectPreviewPopUp from "../../../../components/projectPreviewPopUp";
import { useHistory } from "react-router";
import { EditProjectPopup } from "pages/workInProgress/components/editProjectPopup/index.js";

export const ActionsMenu = ({
  status,
  data,
  clientCompanyName,
  companyId,
  handleGoToWip,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const open = Boolean(anchorEl);
  const [openProjectPopUp, setOpenProjectPopUp] = useState(false);
  const [editProjectData, setEditProjectData] = useState("");
  const projectData = { ...data };
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProjectEdit = (projectData) => {
    setEditProjectData(projectData);
    setOpenProjectPopUp(true);
    handleClose();
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <StyledButton
              width="100px"
              onClick={() => handleProjectEdit(projectData)}
            >
              Add Details
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton
              width="100px"
              onClick={() => handleGoToWip(projectData?.id)}
            >
              Go to WIP
            </StyledButton>
          </StyledMenuItem>

          {/* <StyledMenuItem>
            <StyledButton width="180px" onClick={clickRequestStatusReport}>
              Request status report
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton width="180px" onClick={clickViewStatusReport}>
              View status report
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              Progress payments
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              Change orders
            </StyledButton>
          </StyledMenuItem> */}
        </StyledMenu>

        {/* <ProjectPreviewPopUp
          openPopup={openProjectPopUp}
          setOpenPopup={setOpenProjectPopUp}
          data={data}
        /> */}

        <EditProjectPopup
          setOpenDialog={setOpenProjectPopUp}
          openDialog={openProjectPopUp}
          editProjectData={editProjectData}
          isMobile={isMobile}
          companyId={companyId}
        />
      </>
    );
  }
};

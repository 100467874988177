import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  StyledContainerTitle,
  StyledHeaderContent,
  StyledButtonArea,
  StyledContainerInside,
} from "./styles";
import { FunctionalButton } from "../../../components/functionalButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import { AddPaymentProgressBuyerPopup } from "components/addPaymentProgressBuyerPopup";
import { AddPaymentProgressContractorPopup } from "components/addPaymentProgressContractorPopup";
import { AddChangeOrderBuyerPopup } from "components/addChangeOrderBuyerPopup"
import { AddChangeOrderContractorPopup } from "components/addChangeOrderContractorPopup";
import { getWorkInProgress } from "api/work_in_progress";

export const WorkingInProgressHeader = ({
  title,
  hasButton,
  handleButton,
  marginBottom,
  subTitle,
}) => {
  const [query, setQuery] = useState("");
  const [internalSearchResults, setInternalSearchResults] = useState([]);
  const [openPaymentProgressBuyerPopup, setOpenPaymentProgressBuyerPopup] = useState(false);
  const [openChangeOrderBuyerPopup, setOpenChangeOrderBuyerPopup] = useState(false);
  const [openPaymentProgressContractorPopup, setOpenPaymentProgressContractorPopup] = useState(false);
  const [openChangeOrderContractorPopup, setOpenChangeOrderContractorPopup] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  
  const dummyData = {
    date_approved: "2023-08-22",
    progress_payment_no: "3",
    progress_payment_date: "2023-08-20",
    billings_to_date: "1410",
    holdback_retainage_to_date: "110",
    previous_progress_payments: "33,300,000",
    last_previous_progress_payments: "2520000",
    current_payment_due: "2,430,000",
    cost_to_complete: "17,000,000",
    estimated_cost_of_revenue:"19300000",
    remarks: "Test Remark"
  }
  

  const dummyDataChangeOrder = {
    date_approved: "2023-08-22",
    change_order_no: "3",
    change_order_date: "2023-08-20",
    new_contract_completion_date: "2023-08-20",
    change_in_contract_value: "0",
    change_in_contract_time: "0",
    new_estimated_revenue: "33,300,000",
    new_estimated_cost_of_revenue: "2,430,000",
    total_change_in_contract_value: "200000",
    remarks: "Test Remark"
  }

  const [paymentProgress, setPaymentProgress] = useState("");
  const [paymentProgressBuyer, setPaymentProgressBuyer] = useState({});
  const [paymentProgressContractor, setPaymentProgressContractor] = useState({});
  const [changeOrderContractor, setChangeOrderContractor] = useState({});
  const [changeOrderBuyer, setChangeOrderBuyer] = useState({});




  const handleChangeOrderContractorAdd = () => {
    const id = "1010";
    getWorkInProgress(id, onSuccessChangeOrderContractorAdd, () => { });
  };

  const onSuccessChangeOrderContractorAdd = (res) => {
    setChangeOrderContractor(res);
    setOpenChangeOrderContractorPopup(true);
  };


  const handleChangeOrderBuyerAdd = () => {
    const id = "1010";
    getWorkInProgress(id, onSuccessChangeOrderBuyerAdd, () => { });
  };

  const onSuccessChangeOrderBuyerAdd = (res) => {
    setChangeOrderBuyer(res);
    setOpenChangeOrderBuyerPopup(true);
  };



  const handlePaymentProgressContractorAdd = () => {
    const id = "1010";
    getWorkInProgress(id, onSuccessPaymentProgressContractorAdd, () => { });
  };

  const onSuccessPaymentProgressContractorAdd = (res) => {
    setPaymentProgressContractor(res);
    setOpenPaymentProgressContractorPopup(true);
  };


  const handlePaymentProgressBuyerAdd = () => {
    const id = "1010";
    getWorkInProgress(id, onSuccessPaymentProgressBuyerAdd, () => { });
  };

  const onSuccessPaymentProgressBuyerAdd = (res) => {
    setPaymentProgressBuyer(res);
    setOpenPaymentProgressBuyerPopup(true);
  };

  const addPaymentProgress = (data) => {
    // savePaymentProgress(data, onSuccess, () => {});
  };


  const onSuccess = (res) => {
    setPaymentProgress("");
  };

  useEffect(() => {
    if (!openPaymentProgressBuyerPopup) {
      setPaymentProgress("");
    }
  }, [openPaymentProgressBuyerPopup]);


  return (
    <StyledContainerTitle>
      <StyledContainerInside>
        <StyledHeaderContent marginBottom={marginBottom}>
          {title}
        </StyledHeaderContent>
        {subTitle && (
          <div style={{ marginBottom: "16px", fontSize: "18px" }}>
            {subTitle}
          </div>
        )}
      </StyledContainerInside>
      {hasButton && (

        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="PROGRESS PAYMENT"
              handleButton={handlePaymentProgressBuyerAdd}
              width="260px"
              height="48px"
              fontSize="16px"
              isReversedColor={true}
            />
            <FunctionalButton
              buttonTitle="CHANGE ORDER"
              handleButton={handleChangeOrderBuyerAdd}
              width="180px"
              height="48px"
              isReversedColor={true}
            />
          </StyledButtonArea>

          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="PROGRESS PAYMENT "
              handleButton={handlePaymentProgressContractorAdd}
              width="260px"
              height="48px"
              fontSize="16px"
              isReversedColor={true}
            />
            <FunctionalButton
              buttonTitle="CHANGE ORDER"
              handleButton={handleChangeOrderContractorAdd}
              width="180px"
              height="48px"
              isReversedColor={true}
            />
          </StyledButtonArea>
        </>
      )}

      <AddPaymentProgressBuyerPopup
        setOpenDialog={setOpenPaymentProgressBuyerPopup}
        openDialog={openPaymentProgressBuyerPopup}
        data={paymentProgressBuyer}
        setData={setPaymentProgressBuyer}
        handleButtonFunc={addPaymentProgress}
        isMobile={isMobile}
      />

      <AddPaymentProgressContractorPopup
        setOpenDialog={setOpenPaymentProgressContractorPopup}
        openDialog={openPaymentProgressContractorPopup}
        data={paymentProgressContractor}
        setData={setPaymentProgressContractor}
        handleButtonFunc={addPaymentProgress}
        isMobile={isMobile}
      />

      <AddChangeOrderBuyerPopup
        setOpenDialog={setOpenChangeOrderBuyerPopup}
        openDialog={openChangeOrderBuyerPopup}
        data={changeOrderBuyer}
        setData={setChangeOrderBuyer}
        handleButtonFunc={addPaymentProgress}
        isMobile={isMobile}
      />

      <AddChangeOrderContractorPopup
        setOpenDialog={setOpenChangeOrderContractorPopup}
        openDialog={openChangeOrderContractorPopup}
        data={changeOrderContractor}
        setData={setChangeOrderContractor}
        handleButtonFunc={addPaymentProgress}
        isMobile={isMobile}
      />
    </StyledContainerTitle>


  );
};

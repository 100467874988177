import { useHistory, useLocation } from "react-router-dom";
import { BackButton } from "../../../components/backButton";

export const GoBackButton = ({ buttonText, handleClick }) => {
  let history = useHistory();
  let location = useLocation();
  const path = location.pathname;

  const getPreviousUrl = (n, char, string) => {
    if (n <= 0) {
      return string.length;
    }
    return getPreviousUrl(
      --n,
      char,
      string.substring(0, string.lastIndexOf(char))
    );
  };

  const handleBackPrevious = () => {
    const backUrl = path.substring(0, getPreviousUrl(2, "/", path) + 1);
    history.push(backUrl);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <BackButton
        margin="20px 0px 0px 0px"
        handleClick={handleClick ? handleClick : handleBack}
        buttonText={buttonText}
      />
    </>
  );
};

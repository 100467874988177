import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "./styles";

import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import { useHistory } from "react-router";

export const ActionsMenu = ({ client_id, companyName, status, setData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const seeAllProjects = () => {
    history.push("/underwriter/client/projects", {
      company_name: companyName,
      account_id: client_id,
    });
  };

  const seeAllWorkInProgress = () => {
    history.push("/underwriter/client/workinprogress", {
      company_name: companyName,
      account_id: client_id,
    });
  };

  const seeWorkInProgress = () => {
    history.push("/client/wip", {
      company_name: companyName,
      account_id: client_id,
    });
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <StyledButton width="180px" disabled>
              pledgXeCQS
            </StyledButton>
          </StyledMenuItem>

          {/* <StyledMenuItem>
            <StyledButton
              width="180px"
              //  onClick={clickDelete}
              disabled
            >
              Download eCQS
            </StyledButton>
          </StyledMenuItem> */}
          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              References
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton width="180px" onClick={seeAllWorkInProgress}>
              Work in progress
            </StyledButton>
          </StyledMenuItem>
          {/* <StyledMenuItem>
            <StyledButton width="180px" onClick={seeWorkInProgress}>
              WIP
            </StyledButton>
          </StyledMenuItem> */}
          <StyledMenuItem>
            <StyledButton width="180px" onClick={seeAllProjects}>
              Projects
            </StyledButton>
          </StyledMenuItem>
        </StyledMenu>
      </>
    );
  }
};

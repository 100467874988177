import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};
export const getAssociations = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/associations/get_association_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const sendContractStatusReport = async (token, onSuccess, onFailure) => {
  headers['Content-Type'] = "application/json"
  return await axios.post(`${API_HOST}/contract/status/report/${token}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
}


export const viewContractStatusReport = async (token, onSuccess, onFailure) => {
  headers['Content-Type'] = "application/json"
  return await axios.post(`${API_HOST}/underwriter/csr/view/${token}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
}


export const getUnions = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/unions/get_union_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};


export const getBanks = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/banks/get_bank_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getInsurances = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/insurances/get_insurance_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getCorporates = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/corporate/get_corporate_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getAgents = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/agents/get_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};


export const getPackages = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/packages/get_package_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};


export const getInsuranceTypes = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/insurance_type/get_insurance_type_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};


export const getDocumentTypes = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/document_type/get_document_type_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getConciergePaidAll = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/concierge/get_paid_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};


export const getActiveUsers = async () => {
  getAuth();
  return await axios.get(`${API_HOST}/reports/active_users`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

// export const getUsersReport = async () => {
//   getAuth();
//   return await axios.get(`${API_HOST}/reports/users_report`, { headers })
//     .then((res) => res.data)
//     .catch((err) => {
//       if (err.response.status === 401) {
//         localStorage.removeItem("Authorization");
//         dispatchEvent(new Event("storage"));
//       }
//     });
// };


// export const getUsersActiveCounter = async () => {
//   getAuth();
//   return await axios.get(`${API_HOST}/reports/users_report_active_counter`, { headers })
//     .then((res) => res.data)
//     .catch((err) => {
//       if (err.response.status === 401) {
//         localStorage.removeItem("Authorization");
//         dispatchEvent(new Event("storage"));
//       }
//     });
// };


// export const getUsersInactiveCounter = async () => {
//   getAuth();
//   return await axios.get(`${API_HOST}/reports/users_report_inactive_counter`, { headers })
//     .then((res) => res.data)
//     .catch((err) => {
//       if (err.response.status === 401) {
//         localStorage.removeItem("Authorization");
//         dispatchEvent(new Event("storage"));
//       }
//     });
// };

export const getUsers = async () => {
  getAuth();
  return await axios.get(`${API_HOST}/reports/users`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

// export const getInactiveUsers = async () => {
//   getAuth();
//   return await axios.get(`${API_HOST}/reports/inactive_users`, { headers })
//     .then((res) => res.data)
//     .catch((err) => {
//       if (err.response.status === 401) {
//         localStorage.removeItem("Authorization");
//         dispatchEvent(new Event("storage"));
//       }
//     });
// };


export const getAllIssueStatusReport = async () => {
  getAuth();
  return await axios.get(`${API_HOST}/reports/all_issue_status_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getAllPartnerReferralReport = async () => {
  getAuth();
  return await axios.get(`${API_HOST}/reports/all_partner_referral_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};


export const getAllInstitutionReport = async () => {
  getAuth();
  return await axios.get(`${API_HOST}/reports/all_institution_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};


export const getAllProspectUsersReport = async () => {
  getAuth();
  return await axios.get(`${API_HOST}/reports/all_prospect_users_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

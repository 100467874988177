import React from "react";

export const ApprovedIcon = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="24.4097"
        cy="24.3333"
        rx="23.7108"
        ry="23.7647"
        fill="white"
      />
      <ellipse
        cx="24.4091"
        cy="24.3334"
        rx="19.265"
        ry="19.3088"
        fill="#FF6D1D"
      />
      <path
        d="M16.4043 25L21.7355 30L32.4166 20"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import FilesListTable from "../../../../components/filesListTable/index";
import { MessagePopup } from "./MessagePopup";
import {
    StyledTableCell,
    StyledTableCellActions,
    RespondButton,
    MoreButton,
    StyledTableCellIcon,
    SubmissionIcon,
    StyledTableCellCompanyName,
    StyledTableCellCompanyInfo,
} from "./styles";

import { StyledTableCellIndex } from '../../../../components/filesListTable/styles';


export const SubmissionTable =({ id, headers, data, isQualified }) => {
    const createHeader = (title, align) => {
        return {
          title,
          align: align || "left",
        };
    }

    const handleRespond = () => {
        setOpen(true);
    };

    const handleMore = (ccdcId) => {
        history.push(`/buyers/submissions/${id}/${ccdcId}`);
    };

    const icons = () => {
        return (
            <StyledTableCellIcon align="left" component={'ticon'} >
                <SubmissionIcon isQualified={isQualified} />
            </StyledTableCellIcon>
        );
    };

    const body = (bodyData) => {
        return (
            <>
                <StyledTableCellIndex component={'tr'}>
                    { bodyData.contractor.name }
                </StyledTableCellIndex>
                <StyledTableCellCompanyName component={'tr'}>
                    { bodyData.contractor.union ? 'Union' : 'Non-Union' }
                </StyledTableCellCompanyName>
                <StyledTableCellCompanyName component={'tr'}>
                    { bodyData.contractor.requirements ? 'No' : 'No' }
                </StyledTableCellCompanyName>
                <StyledTableCellCompanyInfo component={'tr'} scope="row">
                    { bodyData.match_rating }
                </StyledTableCellCompanyInfo>
                <StyledTableCellActions component={'tr'} align="right">
                    <RespondButton onClick={handleRespond}>RESPOND</RespondButton>
                </StyledTableCellActions>
                <StyledTableCellActions component={'taction'}>
                    <MoreButton onClick={() => handleMore(bodyData.id)}>MORE</MoreButton>
                </StyledTableCellActions>
            </>
        );
    };



    const [open, setOpen] = useState(false);
    let history = useHistory();

    const allHeaders = [
        createHeader(headers),
        createHeader('Contractor'),
        createHeader('Union'),
        createHeader('Requirements'),
        createHeader('Match Rating')
    ];

    return (
        <>
            <FilesListTable
                headers={allHeaders}
                data={data}
                isQualified={isQualified}
                icons={icons}
                body={body}
            />
            <MessagePopup open={open} setOpen={setOpen} />
        </>
    );
};

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyledContainer } from "./styles";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { AllClientHeader } from "./components/AllClientHeader";
import { AllClientsTable } from "./components/allClientsTable";
import { getTrisuraClientInfo } from "../../data/actions/underwriters";
import { trisuraClientSelector } from "data/selectors/underwriters";
import { GoBackButton } from "pages/buyers/components/goBackButton";

const AllClientPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  let history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const trisuraClientData = useSelector(trisuraClientSelector);

  useEffect(() => {
    if (trisuraClientData.length < 1) {
      dispatch(getTrisuraClientInfo());
    }
  }, []);
  useEffect(() => {
    if (trisuraClientData) {
      setData(trisuraClientData);
    }
  }, [trisuraClientData]);

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <AllClientHeader
        title="All Contractor Clients"
        hasButton={true}
        // handleButton={handleStartNew}
      />
      {data && <AllClientsTable data={data} setData={setData} />}
    </StyledContainer>
  );
};

export default AllClientPage;

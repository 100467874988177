import React, { useEffect, useState } from "react";
import TabContext from "@material-ui/lab/TabContext";
import Box from "@mui/material/Box";
import { TabPanel1 } from "./TabPanel1";

import {
  TabBox,
  StyledTab,
  StyledTabsContainer,
  DividerLine,
  StyledTabPanel,
} from "./styles";
export const SideSection = ({ isMobile }) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <TabBox>
            <StyledTabsContainer
              value={value}
              onChange={handleChange}
              indicatorColor="none"
            >
              <StyledTab value="1" label="Recent Status Reports" />
              {/* <DividerLine/>
              <StyledTab value="2" label="Projects" /> */}
            </StyledTabsContainer>
          </TabBox>
          <StyledTabPanel value="1">
            <TabPanel1 isMobile={isMobile} />
          </StyledTabPanel>
          {/* <StyledTabPanel value="2">
          <TabPanel2 isMobile={isMobile} />
        </StyledTabPanel> */}
        </TabContext>
      </Box>
    </>
  );
};

import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import { StyledRightContainer } from "pages/workInProgress/styles";
import ProjectChartComponent from "./projectChartComponent";

const BottomComponent = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();
  const [estimatedRevenue, setEstimatedRevenue] = useState(0);
  const [earnedRevenue, setEarnedRevenue] = useState(0);
  const [billingsToDate, setBillingsToDate] = useState(0);
  const [estimatedGrossProfit, setEstimatedGrossProfit] = useState(0);
  const [estimatedCostOfRevenue, setEstimatedCostOfRevenue] = useState(0);
  const [costOfRevenue, setCostOfRevenue] = useState(0);
  const [costIncurredToDate, setCostIncurredToDate] = useState(0);
  const [grossProfitToDate, setGrossProfitToDate] = useState(0);
  const [costToComplete, setCostToComplete] = useState(0);
  const [billingInExcessOfCosts, setBillingInExcessOfCosts] = useState(0);
  const [diffInEarningAndBillings, setDiffInEarningAndBillings] = useState(0);
  const [currentPaymentDue, setCurrentPaymentDue] = useState(0);
  const [contractCompletionDate, setContractCompletionDate] = useState("");
  useEffect(() => {
    // calculate total Estimated revenue with change order value
    let totalChangeOrderValue = data?.co_data?.reduce((total, item) => {
      // Convert the values to numbers and add them to the total
      let changeInContractValue = item?.change_in_contract_value
        ? parseFloat(item?.change_in_contract_value)
        : 0;
      return total + changeInContractValue;
    }, 0);
    const totalEstimatedRevenue =
      parseFloat(data?.value) + totalChangeOrderValue;

    // calculate total Estimated cost of revenue with change in cost from change orders
    let changeInContractCost = data?.co_data?.reduce((totalChange, coData) => {
      // Sum up all estimated_cost_for_the_change in co_data array
      return totalChange + parseFloat(coData?.estimated_cost_for_the_change);
    }, 0);
    const totalEstimatedCostOfRevenue =
      parseFloat(data?.original_estimated_cost_of_revenue) +
      changeInContractCost;

    setCostIncurredToDate(parseFloat(data?.ppc_data[0]?.cost_incurred_to_date));
    setEstimatedRevenue(Math.round(totalEstimatedRevenue));
    setEstimatedCostOfRevenue(totalEstimatedCostOfRevenue);

    setContractCompletionDate(
      data?.contract_completion_date
        ? data?.contract_completion_date
        : data?.substantially_completed_date
    );
    setBillingsToDate(
      Math.round(parseFloat(data?.ppc_data[0]?.billings_to_date))
    );
  }, [data]);
  useEffect(() => {
    //calculate billing and Earning in excess of cost
    const diff = earnedRevenue - billingsToDate;
    setDiffInEarningAndBillings(diff);
    if (diff > 0) {
      setBillingInExcessOfCosts(diff);
    }
    if (diff < 0) {
      setBillingInExcessOfCosts(billingsToDate - earnedRevenue);
    }
  }, [billingsToDate, earnedRevenue]);

  useEffect(() => {
    const current_payment_due =
      billingsToDate -
      parseFloat(data?.ppc_data[0]?.previous_progress_payment) -
      parseFloat(data?.ppc_data[0]?.holdback_or_retainage_to_date);
    setCurrentPaymentDue(current_payment_due);
  }, [billingsToDate]);
  useEffect(() => {
    // calculate total earned revenue
    let totalOfEarnedRevenue =
      estimatedCostOfRevenue > 0 && costIncurredToDate > 0
        ? estimatedRevenue * (costIncurredToDate / estimatedCostOfRevenue)
        : 0;

    setEarnedRevenue(Math.round(totalOfEarnedRevenue));
    setCostToComplete(estimatedCostOfRevenue - costIncurredToDate);
  }, [estimatedRevenue, estimatedCostOfRevenue, costIncurredToDate]);
  useEffect(() => {
    // calculate total earned revenue
    let TotalCostOfRevenue =
      estimatedCostOfRevenue > 0 && costIncurredToDate > 0
        ? estimatedCostOfRevenue * (costIncurredToDate / estimatedCostOfRevenue)
        : 0;
    setCostOfRevenue(Math.round(TotalCostOfRevenue));
  }, [estimatedCostOfRevenue, costIncurredToDate]);

  useEffect(() => {
    setEstimatedGrossProfit(estimatedRevenue - estimatedCostOfRevenue);
  }, [estimatedRevenue, estimatedCostOfRevenue]);
  useEffect(() => {
    setGrossProfitToDate(earnedRevenue - costOfRevenue);
  }, [earnedRevenue, costOfRevenue]);

  return (
    <StyledRightContainer style={{ width: "100%", height: "100%" }}>
      <ProjectChartComponent
        title="Earned Revenue"
        subTitle1="Estimated Revenue"
        subTitle2="Earned Revenue"
        subTitle3="Estimated Gross Profit"
        subTitle4="Gross Profit to Date"
        value1={estimatedRevenue}
        value2={earnedRevenue}
        value3={estimatedGrossProfit}
        value4={grossProfitToDate}
      />
      <ProjectChartComponent
        title="Cost to Date"
        subTitle1="Estimated Cost of Revenue"
        subTitle2="Cost Incurred to Date"
        subTitle3="Actual Cost of Revenue"
        subTitle4="Total Cost to Complete"
        value1={estimatedCostOfRevenue}
        value2={costIncurredToDate}
        value3={parseFloat(data?.original_estimated_cost_of_revenue)}
        value4={costToComplete}
      />
      <ProjectChartComponent
        title="Billings to Date"
        subTitle1="Estimated Revenue"
        subTitle2="Billings to Date"
        subTitle3={
          diffInEarningAndBillings < 0
            ? " Billings in Excess of Costs"
            : " Earnings in Excess of Costs"
        }
        subTitle4="Current Payment Due"
        value1={estimatedRevenue}
        value2={billingsToDate}
        value3={billingInExcessOfCosts}
        value4={currentPaymentDue}
      />
      <ProjectChartComponent
        title="Time Lapsed"
        marginRight="0"
        subTitle1="Planned Start Date"
        subTitle2="Actual Start Date"
        subTitle3="Scheduled Completion Date"
        subTitle4="Contract Completion Date"
        date={true}
        value1={data?.planned_start_date}
        value2={data?.actual_start_date}
        value3={data?.substantially_completed_date}
        value4={contractCompletionDate}
      />
    </StyledRightContainer>
  );
};

export default BottomComponent;

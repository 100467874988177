import get from "lodash/get";
import { createSelector } from "reselect";

export const baseUnderwritersSelector = (state) =>
  get(state, "underwriters", {});

export const trisuraClientSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "trisura_client_list", [])
);
export const underwriterClientSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "underwriter_client_list", [])
);
export const clientProjectsSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "client_projects_list", [])
);
export const clientProjectsWipSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "client_projects_list_wip", [])
);

export const clientBondsSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "client_bonds_list", [])
);

export const clientStatusReportCheckSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "client_status_report_check", [])
);

export const statusReportTransactionSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "status_report_transaction_list", [])
);

export const latestStatusReportDetailSelector = createSelector(
  baseUnderwritersSelector,
  (underwriters) => get(underwriters, "latest_status_report_details", [])
);

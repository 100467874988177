export const numberWithCommas = (value) => {
  if (value) {
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
};

export const removeComma = (value) => {
  var num = value;
  if (typeof num === "string") {
    var str = num.replaceAll(",", "");
    num = Number(str);
  }
  return num;
};
export const handleCommaFormat = (value) => {
  var num = removeComma(value);
  return numberWithCommas(num);
};


export const addHTTP = (url) => {

  if (!url.match(/^http[s]?:\/\//)) {
        url = 'http://' + url;
    }
  return url;
}

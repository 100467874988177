import React, { useEffect, useState } from "react";
import {
  StyledBox,
  StyledCircle,
  StyledDateArea,
  StyledMainBox,
  StyledStartComponent,
  StyledTextArea,
} from "./styles";
import LineWithCircles from "./lineWithCircles";
import { ClockIcon } from "assets/wip/clockIcon";
import { formatDateInMonthDay } from "common/dateFormat";

const MiddleComponent = ({ data }) => {
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [totalmonth, setTotalMonths] = useState(0);
  const [ppcData, setPpcData] = useState([]);
  const [changeOrderData, setChangeOrderData] = useState([]);

  const sortDataAscending = (array) => {
    if (array) {
      const sortedArray = [...array].sort((a, b) => a.id - b.id);
      return sortedArray;
    }
    return [];
  };

  useEffect(() => {
    setPpcData(sortDataAscending(data?.ppc_data));
    setChangeOrderData(sortDataAscending(data?.co_data));
    let changeInContractCost = data?.co_data.reduce((totalChange, coData) => {
      // Sum up all estimated_cost_for_the_change in co_data array
      return totalChange + parseFloat(coData.estimated_cost_for_the_change);
    }, 0);
    const totalEstimatedCostOfRevenue =
      parseFloat(data?.original_estimated_cost_of_revenue) +
      changeInContractCost;
    const cost_incurred_to_date = parseFloat(
      data?.ppc_data[0]?.cost_incurred_to_date
    );

    const percentCompleted =
      totalEstimatedCostOfRevenue > 0 && parseFloat(cost_incurred_to_date) > 0
        ? ((cost_incurred_to_date / totalEstimatedCostOfRevenue) * 100).toFixed(
            0
          )
        : 0;
    setPercentCompleted(percentCompleted);

    const monthDifference = (startDateString, endDateString) => {
      if (startDateString && endDateString) {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        let months = 0;

        while (startDate < endDate) {
          startDate.setMonth(startDate.getMonth() + 1);
          months++;
        }

        return months - 1;
      }
      return 0;
    };

    const months = monthDifference(
      data?.actual_start_date,
      data?.contract_completion_date
        ? data?.contract_completion_date
        : data?.substantially_completed_date
    );
    setTotalMonths(months);
  }, [data]);
  return (
    <StyledBox>
      <StyledMainBox style={{ width: "100%" }}>
        <StyledStartComponent style={{ width: "6%" }}>
          <StyledDateArea>
            {formatDateInMonthDay(data?.planned_start_date)}
          </StyledDateArea>
          {/* <StyledCircle>
            <AccessTimeIcon
              sx={{
                fontSize: 25,
                color: "white",
              }}
            />
          </StyledCircle> */}
          <ClockIcon />
          <StyledDateArea>Start</StyledDateArea>
        </StyledStartComponent>
        <StyledMainBox
          style={{
            justifyContent: "center",
            marginLeft: "3px",
            marginRight: "3px",
            width: "90%",
            height: "100px",
          }}
        >
          <LineWithCircles
            totalCircles={totalmonth}
            ppcData={ppcData}
            changeOrderData={changeOrderData}
          />
        </StyledMainBox>
        <StyledStartComponent style={{ width: "6%" }}>
          <StyledDateArea>
            {formatDateInMonthDay(data?.substantially_completed_date)}
          </StyledDateArea>
          <StyledCircle>
            <StyledTextArea>{percentCompleted}%</StyledTextArea>
          </StyledCircle>
          <StyledDateArea>End</StyledDateArea>
        </StyledStartComponent>
      </StyledMainBox>
    </StyledBox>
  );
};

export default MiddleComponent;

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DetailedFormContainer } from "pages/contractorDashboard/styles.js";
import { LargeText, SetupContainer } from "../styles.js";
import { UserList } from "./UserList.js";
import { DetailArea } from "pages/contractorDashboard/detailScreens/styles.js";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import { getUsers } from "../../../api/external_data.js";
import { getUserListOfOneClient, addUserInTheClient, saveSingleClient } from "../../../api/onboarding_client.js";
import { ShowUserListOfOneClientPopup } from "../../../components/showUserListOfOneClientPopup/index.js";
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import { AlertPopup } from "components/alertPopup/alertPopup";

export default function AddUserInExistingClient({ noWidthChange }) {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(filteredData);
  const [openPopup, setOpenPopup] = useState(false);
  const [userListOfOneClient, setUserListOfOneClient] = useState("");
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);



  const handleFilter = async () => {
    await getUsers()
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    handleFilter(query);
  }, []);


  const onSuccess = () => {
    setUserListOfOneClient("");
    handleFilter("");
  }

  //three dots button


  const ShowUserList = (id) => {
    getUserListOfOneClient(id, onSuccessShowUserList, () => { });
  };

  const onSuccessShowUserList = (res) => {
    setUserListOfOneClient(res);
    setOpenPopup(true);
  };


  const addNewUserInClient = (id, formData) => {
    console.log("Coming Soon....")
    // addUserInTheClient(id, formData, onSuccessAddNewUser, () => { });
  };

  const addUser = (id, formData) => {
    addUserInTheClient(id, formData, onSuccessAddNewUser, () => { });
  };



  const onSuccessAddNewUser = (res) => {
    setQuery("");
    handleFilter("");
  };


  return (
    <SetupContainer isMobile={isMobile}>
      <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "All Client"
            : "All Client's List"}
        </LargeText>

        <SearchBar
          filteredData={filteredData}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          searchWidth="100%"
          internalSearch={true}
        />

        <DetailArea>
          <UserList
            filteredData={
              query && query !== "" ? internalSearchResults : filteredData
            }
            showUserListFunc={ShowUserList}
            // addNewUserFunc={addNewUserInClient}
            isMobile={isMobile}
          />
        </DetailArea>

        <ShowUserListOfOneClientPopup
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          data={userListOfOneClient}
          isMobile={isMobile}
        />



        <AddPersonnelPopup
          setOpenDialog={setIsOpenAddUser}
          openDialog={isOpenAddUser}
          handleButtonFunc={addUser}
          label="Add Your Users"
          buttonLabel="ADD USER"
          notShowResume
        />
      </DetailedFormContainer>
    </SetupContainer>
  );
}


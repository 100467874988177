import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import { StyledRightContainer } from "../styles";

import ChartComponent from "./chartComponent";

const RightComponent = ({
  data,
  totalCostIncurredToDate,
  totalEstimatedCostOfRevenue,
}) => {
  const [estimatedRevenue, setEstimatedRevenue] = useState(0);
  const [earnedRevenue, setEarnedRevenue] = useState(0);
  const [totalBillingsToDate, setTotalBillingsToDate] = useState(0);
  const [estimatedGrossProfit, setEstimatedGrossProfit] = useState(0);
  const [costOfRevenue, setCostOfRevenue] = useState(0);
  const [grossProfitToDate, setGrossProfitToDate] = useState(0);

  useEffect(() => {
    const totalEstimatedRevenue = data?.reduce((acc, item) => {
      // add value of the main object
      acc += parseFloat(item?.value) || 0;
      // add all change_in_contract_value from co_data array
      const coTotal = item?.co_data?.reduce((sum, coItem) => {
        return sum + (parseFloat(coItem?.change_in_contract_value) || 0);
      }, 0);

      acc += coTotal;
      return acc;
    }, 0);

    const totalEarnedRevenue = data?.reduce((total, obj) => {
      // Convert value to number
      let value = parseFloat(obj?.value);
      let changeInContractValue = obj?.co_data?.reduce(
        (totalChange, coData) => {
          // Sum up all change_in_contract_value in co_data array
          return totalChange + parseFloat(coData?.change_in_contract_value);
        },
        0
      );

      // Calculate earned revenue for the current object
      let changeInContractCost = obj?.co_data?.reduce((totalChange, coData) => {
        // Sum up all estimated_cost_for_the_change in co_data array
        return totalChange + parseFloat(coData?.estimated_cost_for_the_change);
      }, 0);
      const totalEstimatedCostOfRevenue =
        parseFloat(obj?.original_estimated_cost_of_revenue) +
        changeInContractCost;
      // const percentCompleted =
      //   totalEstimatedCostOfRevenue > 0 &&
      //   parseFloat(obj.ppc_data[0]?.cost_incurred_to_date) > 0
      //     ? (
      //         (parseFloat(obj.ppc_data[0]?.cost_incurred_to_date) /
      //           totalEstimatedCostOfRevenue) *
      //         100
      //       ).toFixed(0)
      //     : 0;
      let totalOfEarnedRevenue =
        totalEstimatedCostOfRevenue > 0 &&
        parseFloat(obj?.ppc_data[0]?.cost_incurred_to_date) > 0
          ? (value + changeInContractValue) *
            (parseFloat(obj?.ppc_data[0]?.cost_incurred_to_date) /
              totalEstimatedCostOfRevenue)
          : 0;

      // Add the earned revenue to the total
      return total + totalOfEarnedRevenue;
    }, 0);

    const total_billings_to_date = data?.reduce((total, item) => {
      const billingsToDate =
        parseFloat(item?.ppc_data[0]?.billings_to_date) || 0; // project list is in descending order so first ppc in array will be the last one
      // sum of all biilingsToDate value of project from last ppc
      return total + billingsToDate;
    }, 0);

    const total_cost_of_revenue = data?.reduce((total, obj) => {
      let value = parseFloat(obj?.original_estimated_cost_of_revenue);
      let changeInContractCost = obj?.co_data?.reduce((totalChange, coData) => {
        // Sum up all estimated_cost_for_the_change in co_data array
        return totalChange + parseFloat(coData?.estimated_cost_for_the_change);
      }, 0);
      const totalEstimatedCostOfRevenue =
        parseFloat(obj?.original_estimated_cost_of_revenue) +
        changeInContractCost;
      // const percentCompleted =
      //   totalEstimatedCostOfRevenue > 0 &&
      //   parseFloat(obj.ppc_data[0]?.cost_incurred_to_date) > 0
      //     ? (
      //         (parseFloat(obj.ppc_data[0]?.cost_incurred_to_date) /
      //           totalEstimatedCostOfRevenue) *
      //         100
      //       ).toFixed(0)
      //     : 0;
      // Calculate estimated cost of revenue of the current object
      let TotalCostOfRevenue =
        totalEstimatedCostOfRevenue > 0 &&
        parseFloat(obj?.ppc_data[0]?.cost_incurred_to_date) > 0
          ? (value + changeInContractCost) *
            (parseFloat(obj?.ppc_data[0]?.cost_incurred_to_date) /
              totalEstimatedCostOfRevenue)
          : 0;
      // (value + changeInContractCost) * (percentCompleted / 100);
      return total + TotalCostOfRevenue;
    }, 0);

    setEstimatedRevenue(Math.round(totalEstimatedRevenue));
    setEarnedRevenue(Math.round(totalEarnedRevenue));
    setTotalBillingsToDate(Math.round(total_billings_to_date));
    setCostOfRevenue(Math.round(total_cost_of_revenue));
  }, [data]);

  useEffect(() => {
    setEstimatedGrossProfit(estimatedRevenue - totalEstimatedCostOfRevenue);
  }, [estimatedRevenue, totalEstimatedCostOfRevenue]);
  useEffect(() => {
    setGrossProfitToDate(earnedRevenue - costOfRevenue);
  }, [earnedRevenue, costOfRevenue]);

  return (
    <StyledRightContainer>
      <StyledRightContainer style={{ width: "100%" }}>
        <ChartComponent
          title="Revenue"
          subTitle1="Estimated Revenue"
          subTitle2="Earned Revenue"
          value1={estimatedRevenue}
          value2={earnedRevenue}
        />
        <ChartComponent
          title="Cost"
          subTitle1="Estimated Cost of Revenue"
          subTitle2="Cost Incurred to Date"
          value1={totalEstimatedCostOfRevenue}
          value2={totalCostIncurredToDate}
        />
        <ChartComponent
          title="Billings"
          subTitle1="Estimated Revenue"
          subTitle2="Billings to Date"
          value1={estimatedRevenue}
          value2={totalBillingsToDate}
        />
        <ChartComponent
          marginRight="0"
          title="Profit/Loss"
          subTitle1="Estimated Gross Profit"
          subTitle2="Gross Profit to Date"
          value1={estimatedGrossProfit}
          value2={grossProfitToDate}
        />
      </StyledRightContainer>
    </StyledRightContainer>
  );
};

export default RightComponent;

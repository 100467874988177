import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const payload = get(action, "payload", []);
  switch (action.type) {
    case "GET_TRISURA_CLIENT_INFO":
      return {
        ...state,
        trisura_client_list: payload,
      };
    case "GET_UNDERWRITER_CLIENT_INFO":
      return {
        ...state,
        underwriter_client_list: payload.data,
      };
    case "GET_CLIENT_PROJECTS_INFO":
      return {
        ...state,
        client_projects_list: payload,
      };
    case "GET_CLIENT_PROJECTS_INFO_WIP":
      return {
        ...state,
        client_projects_list_wip: payload,
      };
    case "GET_CLIENT_BONDS_INFO":
      return {
        ...state,
        client_bonds_list: payload,
      };
    case "GET_STATUS_REPORT_CHECK":
      return {
        ...state,
        client_status_report_check: payload,
      };
    case "POST_STATUS_REPORT_TRANSACTION":
      return {
        ...state,
        status_report_transaction_list: payload,
      };

    case "VIEW_LATEST_STATUS_REPORT":
      return {
        ...state,
        latest_status_report_details: payload,
      };
    default:
      return state;
  }
};

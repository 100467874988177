export const getTrisuraClientInfo = () => (dispatch) => {
  dispatch({
    type: "GET_TRISURA_CLIENT_INFO",
    payload: {
      endpoint: "/trisura/api/clientinfo/data",
      method: "GET",
    },
  });
};

export const getUnderwriterContractorInfo = () => (dispatch) => {
  dispatch({
    type: "GET_UNDERWRITER_CLIENT_INFO",
    payload: {
      endpoint: "/underwriter/get_all_contractors_list",
      method: "GET",
    },
  });
};

export const getClientProjectsInfo =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_CLIENT_PROJECTS_INFO",
      payload: {
        endpoint: "/trisura/api/client/projects",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const getClientProjectsInfoForWip =
  (data, query, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_CLIENT_PROJECTS_INFO_WIP",
      payload: {
        endpoint: `/workinprogress/client/projects?filter=${query}`,
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const getClientBondsInfo = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_CLIENT_BONDS_INFO",
    payload: {
      endpoint: "/trisura/api/client/bonds",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const getStatusReportCheck =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_STATUS_REPORT_CHECK",
      payload: {
        endpoint: "/trisura/api/client/status/report/check",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const postStatusReportTransaction =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "POST_STATUS_REPORT_TRANSACTION",
      payload: {
        endpoint: "/trisura/status/report/transaction",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const latestStatusReportDetails =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "VIEW_LATEST_STATUS_REPORT",
      payload: {
        endpoint: "/trisura/status/report/viewing",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

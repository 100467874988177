import React, { useEffect, useState } from "react";
import {
  StyledChartContainer,
  StyledGraphContainer,
  StyledInnerContainer,
  StyledOutsideContainer,
  StyledTitleContainer,
  StyledValueContainer,
} from "pages/workInProgress/styles";
import { numberWithCommas } from "common/numberFormat";
import DonutChartComponent from "../donutChartComponent";
import { ArrowIcon } from "pages/contractorDashboard/qualificationSection/asset/ArrowIcon";
import DateTimePickerField from "components/dateTimePickerField";
import { FormattedDate } from "react-intl";
import { formatDate } from "common/dateFormat";

const ProjectChartComponent = ({
  marginRight,
  date = false,
  title = " ",
  subTitle1 = " ",
  subTitle2 = " ",
  subTitle3 = " ",
  subTitle4 = " ",
  value1,
  value2,
  value3,
  value4,
}) => {
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);

  useEffect(() => {
    if (!date && value2 > 0) {
      const earnedRevenue = ((value2 / value1) * 100).toFixed(0);
      const remaining = 100 - earnedRevenue;
      setPercentage1(earnedRevenue);
      setPercentage2(remaining);
    }
  }, [value1, value2]);
  useEffect(() => {
    if (date && !isNaN(new Date(value2)) && !isNaN(new Date(value4))) {
      const actualStartDate = new Date(value2 + "T00:00:00Z");
      const completionDate = new Date(value4 + "T00:00:00Z");
      const currentDate = new Date();
      const timezoneOffset = actualStartDate.getTimezoneOffset();
      const adjustedStartDate = new Date(
        actualStartDate.getTime() + timezoneOffset * 60000
      );
      const adjustedCompletionDate = new Date(
        completionDate.getTime() + timezoneOffset * 60000
      );

      const oneDayMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      const totalDuration =
        (adjustedCompletionDate - adjustedStartDate) / oneDayMilliseconds;
      const elapsedDuration =
        (currentDate - adjustedStartDate) / oneDayMilliseconds;

      const timeLapsedPercentage = (
        (elapsedDuration / totalDuration) *
        100
      ).toFixed(0);
      const remainingPercentage = 100 - timeLapsedPercentage;
      setPercentage1(timeLapsedPercentage);
      setPercentage2(remainingPercentage);
    }
  }, [value2, value4]);

  return (
    <StyledGraphContainer
      marginRight={marginRight}
      style={{ width: "24%", height: "100%" }}
    >
      <StyledOutsideContainer>
        <StyledInnerContainer>
          <StyledTitleContainer fontSize="18px">{title}</StyledTitleContainer>
          <StyledChartContainer
            style={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "10px",
              marginBottom: "16px",
              //   border: "2px solid black",
            }}
          >
            <div style={{ width: "100%" }}>
              <DonutChartComponent
                percentage1={percentage1}
                percentage2={percentage2}
                size="150%"
              />
            </div>
            {/* <div
              style={{
                // border: "2px solid black",
                width: "50%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 15,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 7,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    height: 0,
                    borderRadius: "4px",
                    border: "4px #EBE9F1 solid",
                  }}
                ></div>
                <div
                  style={{
                    color: "#888888",
                    fontSize: "12px",
                    fontFamily: "SF Pro Text",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {subTitle1}
                </div>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 7,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    height: 0,
                    borderRadius: "4px",
                    border: "4px #FF6D1D solid",
                  }}
                ></div>
                <div
                  style={{
                    color: "#888888",
                    fontSize: "12px",
                    fontFamily: "SF Pro Text",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {subTitle2}
                </div>
              </div>
            </div> */}
          </StyledChartContainer>
        </StyledInnerContainer>
        <div style={{ width: "100%", border: "1px #EBE9F1 solid" }}></div>
        <StyledInnerContainer
          style={{
            alignItems: "center",
            marginTop: "21px",
            marginBottom: "21px",
            gap: 14,
          }}
        >
          <StyledChartContainer
            style={{
              justifyContent: "flex-start",
            }}
          >
            <StyledTitleContainer color="#888888">Details</StyledTitleContainer>
            <ArrowIcon fill="#888888" width="12" height="12" marginLeft="5px" />
          </StyledChartContainer>
          <StyledChartContainer
            style={{
              justifyContent: "space-between",
            }}
          >
            <StyledInnerContainer>
              <div>
                <StyledValueContainer color="#6E6B7B">
                  {!date ? "$" : ""}
                </StyledValueContainer>

                <StyledValueContainer color="#1A051D">
                  {date && !isNaN(new Date(value1))
                    ? formatDate(value1)
                    : numberWithCommas(value1)}
                </StyledValueContainer>
              </div>
              <StyledTitleContainer
                color="#888888"
                fontSize="12px"
                fontWeight="400"
              >
                {subTitle1}
              </StyledTitleContainer>
            </StyledInnerContainer>

            {/* <StyledValueContainer
              color="#B9B9C3"
              style={{
                opacity: 1,
              }}
            >
              $
            </StyledValueContainer> */}
          </StyledChartContainer>
          <StyledChartContainer
            style={{
              justifyContent: "space-between",
            }}
          >
            <StyledInnerContainer>
              <div>
                <StyledValueContainer color="rgba(255, 109, 29, 0.5)">
                  {!date ? "$" : ""}
                </StyledValueContainer>
                <StyledValueContainer color="#FF6D1D">
                  {date && !isNaN(new Date(value2))
                    ? formatDate(value2)
                    : numberWithCommas(value2)}
                </StyledValueContainer>
              </div>
              <StyledTitleContainer
                color="#888888"
                fontSize="12px"
                fontWeight="400"
              >
                {subTitle2}
              </StyledTitleContainer>
            </StyledInnerContainer>

            {/* <StyledValueContainer
              color="#FF6D1D"
              style={{
                opacity: 1,
              }}
            >
              $
            </StyledValueContainer> */}
          </StyledChartContainer>
          <StyledChartContainer
            style={{
              justifyContent: "space-between",
            }}
          >
            <StyledInnerContainer>
              <div>
                <StyledValueContainer color="#6E6B7B">
                  {!date ? "$" : ""}
                </StyledValueContainer>
                <StyledValueContainer color="#1A051D">
                  {date && !isNaN(new Date(value3))
                    ? formatDate(value3)
                    : numberWithCommas(value3)}
                </StyledValueContainer>
              </div>
              <StyledTitleContainer
                color="#888888"
                fontSize="12px"
                fontWeight="400"
              >
                {subTitle3}
              </StyledTitleContainer>
            </StyledInnerContainer>

            {/* <StyledValueContainer
              color="#B9B9C3"
              style={{
                opacity: 1,
              }}
            >
              $
            </StyledValueContainer> */}
          </StyledChartContainer>
          <StyledChartContainer
            style={{
              justifyContent: "space-between",
            }}
          >
            <StyledInnerContainer>
              <div>
                <StyledValueContainer color="#6E6B7B">
                  {!date ? "$" : ""}
                </StyledValueContainer>
                <StyledValueContainer color="#1A051D">
                  {date && !isNaN(new Date(value4))
                    ? formatDate(value4)
                    : numberWithCommas(value4)}
                </StyledValueContainer>
              </div>
              <StyledTitleContainer
                color="#888888"
                fontSize="12px"
                fontWeight="400"
              >
                {subTitle4}
              </StyledTitleContainer>
            </StyledInnerContainer>

            {/* <StyledValueContainer
              color="#B9B9C3"
              style={{
                opacity: 1,
              }}
            >
              $
            </StyledValueContainer> */}
          </StyledChartContainer>
        </StyledInnerContainer>
      </StyledOutsideContainer>
    </StyledGraphContainer>
  );
};

export default ProjectChartComponent;

import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../constants";
import { StyledTableCell } from "../../../components/filesListTable/styles";

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: ${(props) => (props.isMobile ? "70%" : "50%")};
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 30%;
`;

export const StyledTableCellStatus = styled(StyledTableCell)`
  ${(props) => (props.isMobile ? "width: 20%;" : "")}
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-top: -24px;
  //height: 300px;
  max-height: 600px;
  overflow: auto;
  &&::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &&::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLORS.scrollbarTrack};
    ${(props) => (props.show ? "" : "visibility: hidden;")};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryOrange};
  }
`;

export const ProgressColumn = styled.div`
  width: 100%;
  text-align: center;
`;

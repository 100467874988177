import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyledContainer } from "./styles";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { WorkingInProgressHeader } from "./components/WorkingInProgressHeader";

import { AllProjectsTable } from "./components/allProjectsTable";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";
import { clientProjectsWipSelector } from "data/selectors/underwriters";

const AllWorkInProgress = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();
  const companyName = location.state?.company_name || "";
  const accountId = location.state?.account_id;
  const clientProjects = useSelector(clientProjectsWipSelector);
  let history = useHistory();
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(
      getClientProjectsInfoForWip({ account_id: accountId }, "underway")
    );
  }, []);
  useEffect(() => {
    if (clientProjects) {
      setData(clientProjects);
    }
  }, [clientProjects]);
  const handleGoBack = () => {
    history.push("/underwriter-dashboard");
  };

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <WorkingInProgressHeader
        title="All Work in Progress of"
        subTitle={companyName}
        hasButton={true}
        // handleButton={handleStartNew}
        marginBottom="0"
      />
      {/* {data && (
        <AllProjectsTable
          data={data}
          setData={setData}
          clientCompanyName={companyName}
        />
      )} */}
    </StyledContainer>
  );
};

export default AllWorkInProgress;

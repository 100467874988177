import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { filterPrequaliedContractors } from "../../../api/buyers";
import { StyledBox, PlaceholderText } from "../styles";
import { ReportsCard } from "./ReportsCard";
import { SeeAllText } from "./styles";

export const TabPanel1 = ({ isMobile }) => {
  const [companyList, setCompanyList] = useState("");
  const history = useHistory();

  // useEffect(() => {
  //   handleFilterContractors();
  // }, []);

  // const handleFilterContractors = async () => {
  //   await filterPrequaliedContractors("")
  //     .then((data) => {
  //       setCompanyList(data);
  //     })
  //     .catch((err) => {});
  // };

  const handleSeeContractors = () => {
    history.push("/buyers/prequalified/contractors");
  };

  return (
    <>
      {!(companyList?.length > 0) && (
        <StyledBox boxHeight="80px" borderRadius="10px">
          <PlaceholderText>Status Reports will appear here</PlaceholderText>
        </StyledBox>
      )}
      {companyList?.length > 0 &&
        companyList
          .slice(-6)
          .reverse()
          .map((item, i) => {
            if (item.name) return <ReportsCard {...item} key={i} />;
          })}
      {companyList?.length > 0 && (
        <SeeAllText onClick={handleSeeContractors}>
          See all Status Reports
        </SeeAllText>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";

import { PageHeader } from "../pageHeader";
import {
  StyledContainer,
  StyledInnerContainer,
  StyledMainContainer,
  StyledTitleContainer,
  StyledValueContainer,
} from "pages/workInProgress/styles";
import { useLocation } from "react-router";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import BottomComponent from "./bottomComponent";
import { numberWithCommas } from "common/numberFormat";
import MiddleComponent from "./middleComponent";
import { StyledBoxContainer } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { clientProjectsWipSelector } from "data/selectors/underwriters";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";

const WipForProject = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });

  const location = useLocation();
  const [data, setData] = useState(location.state?.projectData || {});
  const projectId = location?.state?.projectId || "";
  const clientProjects = useSelector(clientProjectsWipSelector);
  const companyId = location.state?.companyId || "";
  const [changeOrder, setChangeOrder] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientProjects) {
      const projectDetails = clientProjects?.find(
        (obj) => obj.id === projectId
      );
      setData(projectDetails);
    }
  }, [clientProjects]);
  useEffect(() => {
    if (clientProjects?.length <= 0) {
      dispatch(
        getClientProjectsInfoForWip({ account_id: companyId }, "underway")
      );
    }
  }, []);

  useEffect(() => {
    const totalChangeOrderValue = data?.co_data?.reduce((total, item) => {
      // Convert the values to numbers and add them to the total
      let changeInContractValue = item.change_in_contract_value
        ? parseFloat(item.change_in_contract_value)
        : 0;
      return total + changeInContractValue;
    }, 0);

    setChangeOrder(totalChangeOrderValue);
  }, [data]);

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton buttonText="See Consolidated WIP" />
      <PageHeader
        subTitle={data?.project_number}
        companyName={data?.project_name}
        project={true}
        hasButton={true}
        marginBottom="8px"
        data={data}
        companyId={companyId}
        // handleButton={handleStartNew}
      />
      <StyledInnerContainer style={{ flexDirection: "row" }}>
        <StyledBoxContainer>
          <div>
            <StyledValueContainer color="#6E6B7B">$</StyledValueContainer>
            <StyledValueContainer color="#1A051D">
              {numberWithCommas(data?.value)}
            </StyledValueContainer>
          </div>
          <StyledTitleContainer
            color="#888888"
            fontSize="11px"
            fontWeight="400"
          >
            Original Contract Price
          </StyledTitleContainer>
        </StyledBoxContainer>
        <StyledBoxContainer>
          <div>
            <StyledValueContainer color="#6E6B7B">$</StyledValueContainer>
            <StyledValueContainer color="#1A051D">
              {numberWithCommas(changeOrder)}
            </StyledValueContainer>
          </div>
          <StyledTitleContainer
            color="#888888"
            fontSize="11px"
            fontWeight="400"
          >
            Change Orders
          </StyledTitleContainer>
        </StyledBoxContainer>
      </StyledInnerContainer>
      <StyledMainContainer style={{ height: "204px" }}>
        <MiddleComponent data={data} />
      </StyledMainContainer>
      <StyledMainContainer style={{ height: "488px" }}>
        <BottomComponent data={data} />
      </StyledMainContainer>
    </StyledContainer>
  );
};

export default WipForProject;

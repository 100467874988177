import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../constants';
import Typography from '@material-ui/core/Typography';
import { typography } from "@mui/system";
import { Avatar } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

export const StyledDivider = styled(Divider)`
  &&{
    margin: 16px 0px 0px;
  }
`;
export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => props.width || "100%"};
  justify-content : ${props => props.justifyContent || "center"};
  ${props => props.margin ? `margin: ${props.margin};` : ""}
  position: relative;
`;

export const RateBox = styled.div`
  width: 19.2%;
  height: 44px;
  background-color: ${props => props.color || "white"};
  justify-content: ${props => props.justifyContent || "center"};
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
`;

export const RateBoxText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "14px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "400"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    margin: 6px 8px 2px;
  }
`;

export const StyledSpan = styled.span`
  font-weight: 500;
`;

export const SmallRateBox = styled.div`
  width: 9.5%;
  border-radius: 3px;
  background-color: ${props => props.color || "#ECEBED"};
  justify-content: center;
  color: white;
`;

export const SmallRateText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 10px;
    font-style: normal;
    font-weight: ${props => props.fontWeight || "400"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
  }
`;

export const AvatarePicture = styled(Avatar)`
  &&{
    width: 42px;
    height: 42px;
    ${props => props.isMobile ? "margin-right: 8px;" : ""}
  }
`;

export const ColumnContent = styled.div`
  display: flex; 
  flex-direction: column;
  margin-left: 12px;
  width:300px;
  justify-content: ${props => props.justifyContent || "center"};
`;

export const ColumnContentStatus = styled.div`
  display: flex; 
  flex-direction: column;
  margin-left: 12px;
  justify-content: ${props => props.justifyContent || "center"};
`;

export const StyledProvidedContainer = styled.div`
  padding: 0 10px;
  background-color: #C0FAD2;
  border-radius: 20px;
  color: #2F9B51;
  text-align: center;
`;

export const StyledContestedContainer = styled.div`
  padding: 0 10px;
  background-color: #FFCACA;
  border-radius: 20px;
  color: #F00101;
  text-align: center;
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column": "row")};;
  justify-content: space-between;
  margin: 24px 0px;
`;

export const TitleText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "22px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "500"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${props => props.color || `${COLORS.darkGrey2}`};
    margin: ${props => props.margin || "0 0 16px"};
  }
`;


export const DarkTitle = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "24px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "600"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${props => props.color || "black"};
    margin: ${props => props.margin || "0 0 16px"};
  }
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
  justify-content: center;
  ${props => props.isMobile ?  "" : "padding: 0px 16px;"}
`;

export const TitleDiv = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center; 
  width: 100%;
  margin: 16px 0 0;
`;


export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 24px;
  color: ${COLORS.black};
`;

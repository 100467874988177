import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "./styles";

import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import ProjectPreviewPopUp from "../../../../components/projectPreviewPopUp";
import { useHistory } from "react-router";

export const ActionsMenu = ({ status, data, clientCompanyName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openProjectPopUp, setOpenProjectPopUp] = useState(false);
  const projectData = { ...data };
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickViewStatusReport = () => {
    history.push("/underwriter/viewing/contract/status/report", {
      statusReportData: projectData,
      clientCompanyName: clientCompanyName,
    });
  };

  const clickRequestStatusReport = () => {
    history.push("/underwriter/sending/contract/status/request", {
      statusReportData: projectData,
      clientCompanyName: clientCompanyName,
    });
  };

  const openProjectDetailPopUp = () => {
    setOpenProjectPopUp(true);
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <StyledButton width="180px" onClick={openProjectDetailPopUp}>
              Project details
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton width="180px" onClick={clickRequestStatusReport}>
              Request status report
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton width="180px" onClick={clickViewStatusReport}>
              View status report
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              Progress payments
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              Change orders
            </StyledButton>
          </StyledMenuItem>
        </StyledMenu>

        <ProjectPreviewPopUp
          openPopup={openProjectPopUp}
          setOpenPopup={setOpenProjectPopUp}
          data={data}
        />
      </>
    );
  }
};

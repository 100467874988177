import React, { useState, useEffect } from "react";
import { handleCommaFormat, removeComma } from "common/numberFormat";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  SmallTitleText,
  ButtonDiv,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import DateTimePickerField from "components/dateTimePickerField";
import { addChangeOrderContractor } from "api/work_in_progress";
import { SubmitSendForApproval } from "./submitSendForApproval";
import { SubmitErrorMessage } from "components/addPaymentProgressContractorPopup/submitErrorMessage.js";
import { useDispatch } from "react-redux";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";
import { ErrorDateApprovedMessage } from "components/addPaymentProgressContractorPopup/errorDateApprovedMessage.js";


export const AddChangeOrderContractorPopup = ({
  setOpenDialog,
  openDialog,
  data,
  oldContractCompletionDate,
  projectData,
  isMobile,
  label,
  buttonLabel,
  companyId,
}) => {
  const dispatch = useDispatch();
  const [coData, setCoData] = useState({});
  const [changeOrderNo, setChangeOrderNo] = useState(0);
  const [changeOrderDate, setChangeOrderDate] = useState("");
  const [dateApproved, setDateApproved] = useState("");
  const [changeInContractTime, setChangeInContractTime] = useState("");
  const [changeInContractValue, setChangeInContractValue] = useState("");
  const [estimatedCostForTheChange, setEstimatedCostForTheChange] =
    useState("");
  const [newEstimatedRevenue, setNewEstimatedRevenue] = useState(
    coData?.project_value_or_estimated_revenue || 0
  );
  const [initialContractCompletionDate, setInitialContractCompletionDate] =
    useState(oldContractCompletionDate);
  const [newContractCompletionDate, setNewContractCompletionDate] = useState(
    initialContractCompletionDate
  );
  const [newEstimatedCostOfRevenue, setNewEstimatedCostOfRevenue] =
    useState("");
  const [validSubmit, setValidSubmit] = useState(false);
  const [remarks, setRemarks] = useState("");
  const oldTotalChangeInContractValue = coData?.project_value_or_estimated_revenue
    ? removeComma(coData?.project_value_or_estimated_revenue)
    : 0;
  const oldNewEstimatedCostOfRevenue = coData?.estimated_cost_of_revenue
    ? removeComma(coData?.estimated_cost_of_revenue)
    : 0;
  const userData = JSON.parse(localStorage.getItem("User"));
  const userEmail = userData?.email;
  const [openSendForApprovalPopup, setOpenSendForApprovalPopup] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [openDateApprovedErrorPopup, setOpenDateApprovedErrorPopup] = useState(false);
  const [errorMessageForDateAprroved, setErrorMessageForDateAprroved] = useState("");

  useEffect(() => {
    if (
      changeOrderDate?.length > 0 &&
      changeInContractValue?.length > 0 &&
      estimatedCostForTheChange?.length > 0
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [changeOrderDate, changeInContractValue, estimatedCostForTheChange]);


  
  useEffect(() => {
    setCoData(data);
    setChangeOrderNo(data?.new_change_order_id);
    setNewContractCompletionDate(data?.contract_completion_date);
  }, [data]);


  
  useEffect(() => {
    const updatedData = { ...coData };
    updatedData["change_order_no"] = changeOrderNo;
    updatedData["change_order_date"] = changeOrderDate ? changeOrderDate : "";
    updatedData["change_in_contract_value"] = changeInContractValue
      ? removeComma(changeInContractValue)
      : 0;
    const totalChangeInContractValue =
      oldTotalChangeInContractValue + removeComma(changeInContractValue);
    setNewEstimatedRevenue(totalChangeInContractValue);
    updatedData["estimated_cost_for_the_change"] = estimatedCostForTheChange
      ? removeComma(estimatedCostForTheChange)
      : 0;
    // updatedData["change_in_contract_time"] = changeInContractTime
    //   ? changeInContractTime
    //   : 0;
    // updatedData["contract_completion_date"] = newContractCompletionDate
    //   ? newContractCompletionDate
    //   : "";
    const totalNewEstimatedCostOfRevenue =
      oldNewEstimatedCostOfRevenue + removeComma(estimatedCostForTheChange);
    setNewEstimatedCostOfRevenue(totalNewEstimatedCostOfRevenue);
    updatedData["new_estimated_revenue"] = totalChangeInContractValue;
    updatedData["new_estimated_cost_of_revenue"] =
      totalNewEstimatedCostOfRevenue;
    updatedData["date_approved"] = dateApproved;
    updatedData["remarks"] = remarks ? remarks : "";
    setCoData(updatedData);
  }, [
    changeOrderNo,
    changeOrderDate,
    changeInContractValue,
    // newContractCompletionDate,
    estimatedCostForTheChange,
    newEstimatedRevenue,
    dateApproved,
    remarks,
  ]);

  useEffect(() => {
    const originalCompletionDate = initialContractCompletionDate || oldContractCompletionDate;
    const newCompletionDate = newContractCompletionDate;

    try {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(originalCompletionDate)) {
        console.log("Invalid original completion date format. Use YYYY-MM-DD");
      }
      if (!/^\d{4}-\d{2}-\d{2}$/.test(newCompletionDate)) {
        console.log("Invalid new completion date format. Use YYYY-MM-DD");
      }

      const originalDate = new Date(originalCompletionDate);
      const newDate = new Date(newCompletionDate);

      const timeDifference = newDate.getTime() - originalDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      if (isNaN(daysDifference)) {
        setChangeInContractTime(0);
        setNewContractCompletionDate(originalCompletionDate);
      } else {
        setChangeInContractTime(daysDifference);
      }
    } catch (error) {
      console.log("Oops!! An error occurred.");
    }
  }, [newContractCompletionDate, initialContractCompletionDate]);

  useEffect(() => {
    const completionDate = initialContractCompletionDate || oldContractCompletionDate;
    const changeInContractTimeNumber = parseInt(changeInContractTime, 10);

    try {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(completionDate)) {
        console.log("Invalid date format. Use YYYY-MM-DD");
      }
      const date = new Date(completionDate);
      date.setDate(date.getDate() + changeInContractTimeNumber);
      const formattedDate = date.toISOString().split("T")[0];
      setNewContractCompletionDate(formattedDate);
    } catch (error) {
      console.log("Opps!!!");
    }
  }, [changeInContractTime, initialContractCompletionDate]);

  const handleAddChangeOrderContractor = () => {
    const change_order_no = changeOrderNo;
    const change_order_date = changeOrderDate ? changeOrderDate : "";
    const change_in_contract_value = changeInContractValue
      ? removeComma(changeInContractValue)
      : 0;
    const change_in_contract_time = changeInContractTime
      ? changeInContractTime
      : 0;
    const new_estimated_revenue = newEstimatedRevenue
      ? removeComma(newEstimatedRevenue)
      : 0;
    const estimated_cost_for_the_change = estimatedCostForTheChange
      ? removeComma(estimatedCostForTheChange)
      : 0;
    const date_approved = dateApproved;
    const send_for_approval = false;
    const new_contract_completion_date = newContractCompletionDate
      ? newContractCompletionDate
      : "";
    const newData = {
      ...coData,
      change_order_no: change_order_no,
      change_order_date: change_order_date,
      change_in_contract_value: change_in_contract_value,
      change_in_contract_time: change_in_contract_time,
      new_contract_completion_date: new_contract_completion_date,
      new_estimated_revenue: new_estimated_revenue,
      estimated_cost_for_the_change: estimated_cost_for_the_change,
      date_approved: date_approved,
      remarks: remarks,
      user_email: userEmail,
      send_for_approval: send_for_approval,
    };
    if (date_approved !== "" && date_approved !== null) {
      addChangeOrderContractor(
        newData,
        () => {
          setOpenDialog(false);
          setChangeOrderNo("");
          setChangeOrderDate("");
          setDateApproved("");
          setChangeInContractTime(0);
          setChangeInContractValue("");
          setEstimatedCostForTheChange("");
          setNewEstimatedRevenue("");
          setNewContractCompletionDate("");
          setNewEstimatedCostOfRevenue("");
          setRemarks("");

          dispatch(
            getClientProjectsInfoForWip({ account_id: companyId }, "underway")
          );
        },
        () => { }
      );
    } else {
      setErrorMessageForDateAprroved("Date Approved field must be filled up before saving.");
      setOpenDateApprovedErrorPopup(true);
    }

  };

  const handleAddChangeOrderContractorForApproval = () => {
    const change_order_no = changeOrderNo;
    const change_order_date = changeOrderDate ? changeOrderDate : "";
    const change_in_contract_value = changeInContractValue
      ? removeComma(changeInContractValue)
      : 0;
    const change_in_contract_time = changeInContractTime
      ? changeInContractTime
      : 0;
    const new_estimated_revenue = newEstimatedRevenue
      ? removeComma(newEstimatedRevenue)
      : 0;
    const estimated_cost_for_the_change = estimatedCostForTheChange
      ? removeComma(estimatedCostForTheChange)
      : 0;
    const date_approved = dateApproved;
    const new_contract_completion_date = newContractCompletionDate
      ? newContractCompletionDate
      : "";
    const send_for_approval = true;
    const buyer_email = projectData?.owner_email
      ? projectData?.owner_email
      : projectData?.consultant_email
        ? projectData?.consultant_email
        : "";
    const buyer_name = projectData?.owner_contact
      ? projectData?.owner_contact
      : projectData?.consultant_contact
        ? projectData?.consultant_contact
        : "";
    const general_contractor = userData?.institution?.name
      ? userData?.institution?.name
      : "";
    const project_name = projectData?.project_name
      ? projectData?.project_name
      : "";
    const newData = {
      ...coData,
      change_order_no: change_order_no,
      change_order_date: change_order_date,
      change_in_contract_value: change_in_contract_value,
      change_in_contract_time: change_in_contract_time,
      new_contract_completion_date: new_contract_completion_date,
      new_estimated_revenue: new_estimated_revenue,
      estimated_cost_for_the_change: estimated_cost_for_the_change,
      date_approved: date_approved,
      remarks: remarks,
      user_email: userEmail,
      send_for_approval: send_for_approval,
      buyer_email: buyer_email,
      buyer_name: buyer_name,
      general_contractor: general_contractor,
      project_name: project_name,
    };

    if (buyer_email === "") {
      setOpenErrorPopup(true);
    } else {
      if (date_approved !== "" && date_approved !== null) {
        setErrorMessageForDateAprroved("Date Approved field must be empty before sending approval.");
        setOpenDateApprovedErrorPopup(true);
      } else {
        setProjectInfo(newData);
        setOpenSendForApprovalPopup(true);
      }

    }

  };

  const handleClose = () => {
    setOpenDialog(false);
    setChangeOrderNo(coData?.new_change_order_id);
    setChangeOrderDate("");
    setDateApproved("");
    // setChangeInContractTime(0);
    setChangeInContractValue("");
    setEstimatedCostForTheChange("");
    setNewEstimatedRevenue("");
    // setNewContractCompletionDate("");
    setNewEstimatedCostOfRevenue("");
    setRemarks("");
  };

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <StyledPaper elevation={0} isMobile={isMobile}>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
          <FormTitle
            style={{
              marginTop: "10px",
              color: "#1A051D",
              fontSize: "22px",
              fontWeight: "600",
              fontStyle: "normal",
              lineHeight: "24px",
            }}
          >
            {label || "Change Order"}
          </FormTitle>

          <SmallTitleText style={{
            marginBottom: 10,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}>
            {coData?.project_name}
          </SmallTitleText>

          <InfoArea isMobile={isMobile} marginTop="0px">
            <div
              style={{
                margin: "0px",
                marginBottom: "-15px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Input
                id="oneThirdInputWrap"
                label="Change Order #"
                type="text"
                placeholder="0"
                labelSize="12px"
                fontSize="18px"
                value={changeOrderNo}
                setValue={setChangeOrderNo}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <DateTimePickerField
                showDateField={true}
                dateWidth="33%"
                paddingRight="16px"
                dateLabel="Change Order Date*"
                dateValue={changeOrderDate}
                setDateValue={setChangeOrderDate}
              />

              <DateTimePickerField
                showDateField={true}
                dateWidth="34%"
                paddingRight="0px"
                dateLabel="Date Approved"
                dateValue={dateApproved}
                setDateValue={setDateApproved}
              />
            </div>

            <div
              style={{
                margin: "0px",
                marginBottom: "-15px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Input
                id="oneThirdInputWrap"
                label="Change in Contract Value*"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(changeInContractValue)}
                setValue={setChangeInContractValue}
                noCheckIcon={true}
                noErrorBorder={true}
              />

              <Input
                id="oneThirdInputWrap"
                label="Change in Contract Time(Days)*"
                type="text"
                placeholder="0"
                labelSize="12px"
                fontSize="18px"
                value={changeInContractTime}
                setValue={setChangeInContractTime}
                noCheckIcon={true}
                noErrorBorder={true}
              />

              <Input
                id="oneThirdInputWrap last"
                label="Estimated Cost for the Change*"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(estimatedCostForTheChange)}
                setValue={setEstimatedCostForTheChange}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </div>

            <div
              style={{
                margin: "0px",
                marginBottom: "-15px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Input
                id="oneThirdInputWrap"
                label="New Estimated Revenue"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(newEstimatedRevenue)}
                noCheckIcon={true}
                noErrorBorder={true}
                backgroundColor="rgba(235, 233, 241, 0.75)"
                disabled
              />

              <DateTimePickerField
                showDateField={true}
                dateWidth="33%"
                paddingRight="16px"
                dateLabel="New Contract Completion Date*"
                dateValue={newContractCompletionDate}
                setDateValue={setNewContractCompletionDate}
              />

              <Input
                id="oneThirdInputWrap last"
                label="New Estimated Cost of Revenue"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(newEstimatedCostOfRevenue)}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
                backgroundColor="rgba(235, 233, 241, 0.75)"
                disabled
              />
            </div>

            <div
              style={{
                margin: "0px",
                display: "flex",
                flexDirection: "row",
                width: "104%",
              }}
            >
              <Input
                id="fullWidthInputWrap last"
                label="Remarks"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={remarks}
                setValue={setRemarks}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
              />
            </div>
          </InfoArea>

          <SubmitSendForApproval
            isMobile={isMobile}
            data={projectInfo}
            openPopup={openSendForApprovalPopup}
            setOpenPopup={setOpenSendForApprovalPopup}
            setOpenDialog={setOpenDialog}
          />

          <SubmitErrorMessage
            isMobile={isMobile}
            openPopup={openErrorPopup}
            setOpenPopup={setOpenErrorPopup}
          />

          <ErrorDateApprovedMessage
            isMobile={isMobile}
            openPopup={openDateApprovedErrorPopup}
            setOpenPopup={setOpenDateApprovedErrorPopup}
            errorMessage={errorMessageForDateAprroved}
          />

          <ButtonDiv isMobile={isMobile}>
            <FunctionalButton
              disabled={validSubmit}
              width="35%"
              marginTop="32px"
              marginBottom="34x"
              marginRight="16px"
              handleButton={() => {
                handleAddChangeOrderContractor();
              }}
              buttonTitle={buttonLabel || "SAVE AS APPROVED"}
            />

            <FunctionalButton
              disabled={validSubmit}
              width="35%"
              marginTop="32px"
              marginBottom="34x"
              marginRight="0px"
              handleButton={() => {
                handleAddChangeOrderContractorForApproval();
              }}
              buttonTitle={buttonLabel || "SEND FOR APPROVAL"}
            />
          </ButtonDiv>
        </StyledPaper>
      </Dialog>
    </>
  );
};

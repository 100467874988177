export const NumberFormatOnly = (number) => {
  const userLocale = navigator.language || navigator.userLanguage;

  const formatter = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let sanitizedNumber = null;

  if (number !== null && number !== undefined) {
     sanitizedNumber = number.toString().replace(/[^\d.]/g, "");
  } 

  let formattedNumber = 0;

  if (sanitizedNumber !== null) {
    formattedNumber = formatter.format(sanitizedNumber);
  }

  return formattedNumber
}


export const NumberFormatWithCurrency = (number) => {
  const userLocale = navigator.language || navigator.userLanguage;
  let currencyCode = userLocale.toUpperCase();

  switch (currencyCode) {
    case "BN":
      currencyCode = "BDT";
      break;
    case "EN":
      currencyCode = "USD";
      break;
    case "AF":
      currencyCode = "AFN";
      break;
    case "AL":
      currencyCode = "ALL";
      break;
    case "DZ":
      currencyCode = "DZD";
      break;
    case "AD":
      currencyCode = "EUR";
      break;
    case "AO":
      currencyCode = "AOA";
      break;
    case "AI":
      currencyCode = "XCD";
      break;
    case "AG":
      currencyCode = "XCD";
      break;
    case "AR":
      currencyCode = "ARS";
      break;
    case "AM":
      currencyCode = "AMD";
      break;
    case "AW":
      currencyCode = "AWG";
      break;
    case "AU":
      currencyCode = "AUD";
      break;
    case "AT":
      currencyCode = "EUR";
      break;
    case "AZ":
      currencyCode = "AZN";
      break;
    case "BS":
      currencyCode = "BSD";
      break;
    case "BH":
      currencyCode = "BHD";
      break;
    case "BD":
      currencyCode = "BDT";
      break;
    case "BB":
      currencyCode = "BBD";
      break;
    case "BY":
      currencyCode = "BYN";
      break;
    case "BE":
      currencyCode = "EUR";
      break;
    case "BZ":
      currencyCode = "BZD";
      break;
    case "BJ":
      currencyCode = "XOF";
      break;
    case "BM":
      currencyCode = "BMD";
      break;
    case "BT":
      currencyCode = "BTN";
      break;
    case "BO":
      currencyCode = "BOB";
      break;
    case "BA":
      currencyCode = "BAM";
      break;
    case "BW":
      currencyCode = "BWP";
      break;
    case "BR":
      currencyCode = "BRL";
      break;
    case "IO":
      currencyCode = "USD";
      break;
    case "BN":
      currencyCode = "BND";
      break;
    case "BG":
      currencyCode = "BGN";
      break;
    case "BF":
      currencyCode = "XOF";
      break;
    case "BI":
      currencyCode = "BIF";
      break;
    case "KH":
      currencyCode = "KHR";
      break;
    case "CM":
      currencyCode = "XAF";
      break;
    case "CA":
      currencyCode = "CAD";
      break;
    case "CV":
      currencyCode = "CVE";
      break;
    case "KY":
      currencyCode = "KYD";
      break;
    case "CF":
      currencyCode = "XAF";
      break;
    case "TD":
      currencyCode = "XAF";
      break;
    case "CL":
      currencyCode = "CLP";
      break;
    case "CN":
      currencyCode = "CNY";
      break;
    case "CX":
      currencyCode = "AUD";
      break;
    case "CC":
      currencyCode = "AUD";
      break;
    default:
      currencyCode = "USD";
  }


  const formatter = new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formattedNumber = formatter.format(number);
  return formattedNumber
}



import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyledContainer } from "./styles";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { AllClientHeader } from "./components/AllClientHeader";

import { AllProjectsTable } from "./components/allProjectsTable";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { getClientProjectsInfo } from "data/actions/underwriters";
import { clientProjectsSelector } from "data/selectors/underwriters";
import { getSingleProject } from "api/prequalify";
import { saveProject } from "api/dashboard";
import { AddProjectPopup } from "components/addProjectPopup";

const AllProjectPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();
  const companyName = location.state?.company_name || "";
  const accountId = location.state?.account_id;
  const clientProjects = useSelector(clientProjectsSelector);
  const [project, setProject] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  let history = useHistory();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getClientProjectsInfo({ account_id: accountId }));
  }, []);
  useEffect(() => {
    if (clientProjects) {
      setData(clientProjects);
    }
  }, [clientProjects]);
  const handleGoBack = () => {
    history.push("/underwriter-dashboard");
  };

  const addProject = (data) => {
    saveProject(data, onSuccess, () => {});
  };


  const onSuccess = () => {
    setProject("");
  };


  const editProject = (id) => {
    getSingleProject(id, onSuccessEdit, () => { });
  };
  const onSuccessEdit = (res) => {
    setProject(res);
    setOpenPopup(true);
  };


  <AddProjectPopup
    setOpenPopup={setOpenPopup}
    openPopup={openPopup}
    data={project}
    setData={setProject}
    handleButtonFunc={addProject}
    isMobile={isMobile}
  />



  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <AllClientHeader
        title="All Projects of"
        subTitle={companyName}
        hasButton={true}
        // handleButton={handleStartNew}
        marginBottom="0"
      />
      {data && (
        <AllProjectsTable
          data={data}
          setData={setData}
          clientCompanyName={companyName}
          editFunc={editProject}
        />
      )}
    </StyledContainer>
  );
};

export default AllProjectPage;

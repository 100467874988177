import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { emailRE } from "../../common/regex";
import Input from "../../components/inputField";
import { RadioButton } from "../../components/radioButton";
import { FunctionalButton } from "../../components/functionalButton";
import { login } from "../../api/onboarding";

import {
  FormContainer,
  Content,
  FormCard,
  FormTitle,
  StyledRememberMe,
  StyledSubtext,
  StyledSpan,
  ErrorText,
  StyledForgotPassword,
  OptionArea,
} from "./styles";

export const Login = ({ isMobile }) => {
  const [email, setEmail] = useState(
    localStorage.getItem("User_Pledgx_Email") || ""
  );
  const [password, setPassword] = React.useState("");
  const history = useHistory();
  const [remember, setRemember] = useState(
    localStorage.getItem("User_Pledgx_Email") ? true : false
  );
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasErrorVerify, setHasErrorVerify] = useState(false);

  const handleChange = () => {
    setRemember(!remember);
  };

  useEffect(() => {
    if (remember) {
      localStorage.setItem("User_Pledgx_Email", email);
    } else {
      localStorage.removeItem("User_Pledgx_Email");
    }
  }, [remember]);

  const handleLogin = async () => {
    await login({ email, password, remember }, onSuccess, onFailure);
  };

  const onSuccess = (data) => {
    const email_split = data.user?.email.split("@");

    if (data?.status === "active" || !data?.status) {
      if (data.user?.userType === "Contractor" && data?.staff !== true) {
        history.push("/contractor-dashboard");
        localStorage.setItem("current_dashboard_url", "/contractor-dashboard");
      } else if (data.user?.userType === "Buyer" && data?.staff !== true) {
        history.push("/buyer-dashboard");
        localStorage.setItem("current_dashboard_url", "/buyer-dashboard");
      } else if (
        data.user?.userType === "Underwriter" &&
        data?.staff !== true
      ) {
        history.push("/underwriter-dashboard");
        localStorage.setItem("current_dashboard_url", "/underwriter-dashboard");
      } else if (
        data.user?.userType === "Contractor & Buyer" &&
        data?.staff !== true
      ) {
        history.push("/toolbar");
      } else if (data?.staff === true && email_split[1] === "pledgx.com") {
        history.push("/toolbar");
      } else if (
        data.user?.userType === "Agent/Broker" &&
        data?.staff !== true
      ) {
        history.push("/service-provider-dashboard");
        localStorage.setItem(
          "current_dashboard_url",
          "/service-provider-dashboard"
        );
      }
    } else if (data?.status === "inactive") {
      history.push("/onboarding");
    }
  };

  const onFailure = (error) => {
    if (error?.status === 401) {
      setHasError(true);
      setErrorMessage(error?.data?.message);
    }
    if (error?.status === 403) {
      setHasErrorVerify(true);
      setErrorMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    setHasError(false);
    setHasErrorVerify(false);
    setErrorMessage("");
  }, [email, password]);

  return (
    <FormContainer isMobile={isMobile}>
      <Content isMobile={isMobile} marginTop="50px">
        <div
          onClick={() => history.push("/account/login")}
          style={{ width: "100%", cursor: "pointer" }}
        >
          <PledgxLogo />
        </div>
        <FormCard>
          <FormTitle>Login</FormTitle>
          <StyledSubtext>Change the way you qualify!</StyledSubtext>
          <Input
            label="Your Email"
            type="text"
            placeholder="name@company.com"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={email}
            setValue={(val) => setEmail(val)}
            validateFunc={(item) => emailRE.test(item)}
            hasError={hasError ? hasError : hasErrorVerify}
            noCheckIcon={true}
          />
          {hasErrorVerify && (
            <ErrorText>
              {errorMessage}
              <StyledSpan onClick={() => history.push("/account/resend")}>
                {" "}
                Click here to resend confirmation
              </StyledSpan>
            </ErrorText>
          )}
          <Input
            label="Password"
            type="password"
            placeholder="Password"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={password}
            setValue={(val) => setPassword(val)}
            validateFunc={(item) => item.length > 0}
            hasError={hasError}
            noCheckIcon={true}
          />

          {hasError && <ErrorText>{errorMessage}</ErrorText>}
          <OptionArea>
            <StyledRememberMe
              control={
                <RadioButton isChecked={remember} handleChange={handleChange} />
              }
              label="Remember me"
              labelPlacement="end"
            />
            <StyledForgotPassword
              onClick={() => history.push("/account/forgot")}
            >
              Forgot password?
            </StyledForgotPassword>
          </OptionArea>
          <FunctionalButton
            buttonTitle="Login"
            handleButton={handleLogin}
            width="100%"
            marginRight="0px"
            disabled={disabled}
          />
          <StyledSubtext>
            Not Registered Yet?
            <StyledSpan onClick={() => history.push("/account/register")}>
              {" "}
              Create an Account
            </StyledSpan>
          </StyledSubtext>
        </FormCard>
      </Content>
    </FormContainer>
  );
};

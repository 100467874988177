import React, { useState } from "react";

import { MediumText, RegularText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import ReferencesIcon from "../../../assets/dashboardIcons/referencesIcon";
import Rating from "../../../assets/dashboardIcons/rating";
import refComingSoon from "assets/dashboardIcons/refComingSoon.svg";
import BondsIcon from "assets/dashboardIcons/BondsIcon";

export const Bonds = ({ isMobile }) => {
  const handleAddRef = () => {};
  const [comingSoon, setComingSoon] = useState(true);

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleAddRef}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <>
              <BondsIcon width={55} height={59} isMobile={isMobile} />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
          {!comingSoon && (
            <>
              <BondsIcon width={55} height={59} isMobile={isMobile} />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>Bonds</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <>
                <IntroArea>
                  <BondsIcon />
                  <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle>
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="View Bonds"
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                    disabled={true}
                  />
                </ButtonContainer>
              </>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <BondsIcon />
                  <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle>
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="View Bonds"
                    handleButton={handleAddRef}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
